import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import URLs from "constants/urls";
import Cookie from "js-cookie";
import Slider from "react-slick";
import { StyledSnackbar, StyledAlert } from "components/StyledComponents/styledComponents";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LoginPage() {
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    customPaging: (i) => (
      <div style={{ color: "#ffffff", fontSize: "20px", textAlign: "center" }}>{"__"}</div>
    ),
  };

  const financeSlides = [
    {
      title: "Seamless Application Tracking",
      description:
        "Track all your loan, insurance, and mutual fund applications with ease and precision, ensuring you never miss a deadline.",
    },
    {
      title: "Smart Financial Management",
      description:
        "Monitor and manage your financial inflows and outflows, enabling effective budget control and maximized earnings.",
    },
    {
      title: "Detailed Monthly Insights",
      description:
        "Access comprehensive monthly financial reports and visual graphs to understand your earnings, payouts, and operational expenses.",
    },
    {
      title: "Streamlined Client Communication",
      description:
        "Enhance your service quality by maintaining clear, efficient communication with your clients to boost satisfaction and trust.",
    },
  ];

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setAlert("Please enter both username and password.", "error");
      return;
    }

    setLoading(true);
    // const apiUrl = "https://185.193.19.156:8443/fintrack/validate";
    // const apiUrl = "https://stage-javaapi.bridgeconnect-runs.com/fintrack/validate";
    const apiUrl = URLs.logiApiUrl;
    const credentials = {
      employeeCode: username,
      employeePassword: password,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const errorMsg = await response.text();
        throw new Error(errorMsg || "Login failed. Please try again.");
      }
      const token = await response.text();

      Cookie.set("username", username, { expires: rememberMe ? 7 : undefined });
      Cookie.set("access_token", "true", { expires: rememberMe ? 7 : undefined });
      Cookie.set("jwt_token", token, { expires: rememberMe ? 7 : undefined });
      navigate("/");
      setUsername("");
      setPassword("");
      setAlert("Login successful!", "success");
    } catch (error) {
      setAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100vh",
        background: "linear-gradient(to bottom right, #2E6E9E 60%, white 20%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "900px",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 3000,
      }}
    >
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <Grid
        item
        sm={12}
        md={6}
        sx={{ display: "flex", margin: "auto", flexDirection: "column", alignItems: "center" }}
      >
        <Paper elevation={3} sx={{ borderRadius: "10px", padding: 4, width: "80%", maxWidth: 400 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox checked={rememberMe} onChange={handleSetRememberMe} />}
              label="Remember Me"
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              sx={{ marginTop: 2, color: "#ffffff" }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Login"}
            </Button>
          </form>
        </Paper>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "90%",
            borderRadius: "10px",
            background: "#2E6E9E",
            margin: "auto",
            padding: "40px",
          }}
        >
          <AppBar position="static" sx={{ background: "#2E6E9E" }}>
            <Toolbar>
              <Typography
                variant="h1"
                sx={{
                  flexGrow: 1,
                  fontWeight: "bold",
                  color: "#2E6E9E",
                  backgroundColor: "#ffffff",
                  padding: "10px 20px",
                  borderRadius: "8px",
                  textAlign: "center",
                  margin: "0 auto",
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "2rem",
                    lg: "2rem",
                  },
                }}
              >
                Fintrack
              </Typography>
            </Toolbar>
          </AppBar>
          <Slider
            {...sliderSettings}
            style={{
              marginTop: "20px",
              width: "100%",
              minWidth: "300px",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            {financeSlides.map((slide, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: "#ffffff", fontWeight: "700", marginBottom: "10px" }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#ffffff", lineHeight: 1.6, fontSize: "16px" }}
                >
                  {slide.description}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
