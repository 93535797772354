import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // AG Grid styles
import "ag-grid-community/styles/ag-theme-alpine.css"; // AG Grid theme
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes, { symbol } from "prop-types";
import { styled } from "@mui/system";
import "../../../index.css";
import Cookie from "js-cookie";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  TableContainer,
  Paper,
  Grid,
  TextField,
  Dialog,
  Typography,
  CircularProgress,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  Autocomplete,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { employeeStyles } from "components/Styles/employeeMasterStyles";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import URLs from "constants/urls";
import { selectScrollStyle, CustomPaper } from "components/Styles/globalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function AccessControl() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [tableData, setTableData] = useState([]);
  const [loginAccessData, setLoginAccessData] = useState([]);
  const [employeeCode, setEmployeeCode] = useState(0);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const jwt_token = Cookie.get("jwt_token");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const MInput = inputStyle();
  const fetchEmployeeData = async () => {
    setLoading(true);
    const url = URLs.employeeMasterGetUrl;
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      setAlert("Data not Fetched: " + error.message, "error");
    }
    setLoading(false);
  };
  const fetchLoginAcceccedData = async () => {
    setLoading(true);
    const url = URLs.appAccessURl;
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLoginAccessData(data);
      resetForm();
    } catch (error) {
      setAlert("Data not Fetched: " + error.message, "error");
    }
    setLoading(false);
  };
  const accesControlApiCall = async () => {
    setLoading(true);
    const url = URLs.accessControlApi;
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify({
          employeeId: employeeId,
          companyId: companyId,
          employeeCode: employeeCode,
          employeePassword: password,
        }),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) {
        // Handle error response
        setAlert(data.message || "An error occurred", "error");
      } else {
        // Handle success response
        setAlert(data.message || "Success", "success");
        fetchLoginAcceccedData();
      }
    } catch (error) {
      // Handle network or parsing errors
      setAlert("Data not Fetched: " + error.message, "error");
    } finally {
      setLoading(false); // Ensure loading is stopped even if an error occurs
    }
  };
  const updatePasswordData = async () => {
    setLoading(true);
    const url = URLs.PasswordUpdate;
    try {
      const encodedPassword = btoa(password); // Base64 encode password
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`,
        },
        body: JSON.stringify({
          employeeId: employeeId,
          companyId: companyId,
          employeeCode: employeeCode,
          employeePassword: encodedPassword,
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) {
        // Handle error response
        const data = await response.json(); // Assuming errors are returned in JSON
        setAlert(data.message || "An error occurred", "error");
      } else {
        const data = await response.text(); // Parse response as plain text
        setAlert(data || "Password updated successfully", "success");
        fetchLoginAcceccedData(); // Fetch updated data
        resetForm();
      }
    } catch (error) {
      // Handle network or parsing errors
      console.error("Network Error:", error); // Debugging log
      setAlert("Data not Fetched: " + error.message, "error");
    } finally {
      setLoading(false); // Ensure loading is stopped even if an error occurs
    }
  };
  const handleViewClick = (rowData) => {
    setIsEditMode(true);
    setEmployeeCode(rowData.employeeCode);
    setCompanyId(rowData.companyId);
    setEmployeeId(rowData.employeeId);
  };
  const handleDeleteClick = (emId) => {
    setOpenDialog(true);
    setEmployeeId(emId);
  };

  const removeAccessApi = async () => {
    setLoading(true);
    const url = `${URLs.removeAccess}/${employeeId}`;

    try {
      const response = await fetch(url, {
        method: "DELETE", // Specify the HTTP method
        headers: {
          "Content-Type": "application/json", // Optional but good practice
          Authorization: `Bearer ${jwt_token}`,
        },
      });

      const data = await response.text(); // Parse response as plain text
      if (!response.ok) {
        throw new Error(data || "Failed to Delete");
      }

      setAlert(data || "Access Removed Successfully", "success");
      fetchLoginAcceccedData(); // Fetch updated data
    } catch (error) {
      setAlert(error.message, "error");
    } finally {
      setLoading(false);
      setOpenDialog(false);
      resetForm();
    }
  };
  useEffect(() => {
    document.querySelectorAll("input").forEach((input) => {
      input.autocomplete = "off";
    });
  }, []);

  useEffect(() => {
    fetchEmployeeData();
    fetchLoginAcceccedData();
  }, []);

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    setEmployeeId("");
    setPassword("");
    setEmployeeCode();
    setCompanyId("");
    setIsEditMode(false);
    setShowPassword(false);
  };

  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data; // Get the row data using the grid API
    return (
      <div>
        <ViewButton onClick={() => handleViewClick(rowData)}>
          <EditIcon />
        </ViewButton>
        <DeleteButton onClick={() => handleDeleteClick(rowData.employeeId)}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Employee Name",
      field: "employeeName",
      sortable: true,
      filter: true,
      flex: 2, // More flex to give it more space
    },
    {
      headerName: "Dsa Name",
      field: "dsaName",
      sortable: true,
      filter: true,
      flex: 2,
    },
    {
      headerName: "Employee Code",
      field: "employeeCode",
      sortable: true,
      filter: true,
      flex: 2,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer, // Custom action cell renderer
      width: 100,
    },
  ];
  const handleConfirmDelete = () => {
    removeAccessApi();
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (isEditMode) {
      updatePasswordData();
    } else {
      accesControlApiCall();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={employeeStyles.mainTable}>
        <Grid container spacing={2}>
          <Dialog
            open={loading}
            onClose={() => setLoading(false)}
            aria-labelledby="loading-dialog-title"
          >
            <DialogContent
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                Loading, please wait...
              </Typography>
            </DialogContent>
          </Dialog>
          <Dialog open={openDialog}>
            <DialogContent sx={subDialogContentStyle}>
              <DialogContentText sx={dialogContentTextStyle}>
                {`Are you sure you want to remove Access control to ${employeeCode} employee `}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={employeeStyles.dialogActions}>
              <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
              <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
                No
              </StyledButton>
            </DialogActions>
          </Dialog>
          <Grid item xs={12} md={7}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)", ...employeeStyles.mtable }}
            >
              <div style={{ position: "relative", width: "100%" }}>
                <StyledTypography>
                  {!isEditMode ? "Access Control" : "Re-create Password"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetForm}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <Grid sx={employeeStyles.root}>
                <StyledSnackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  severity={alertType}
                >
                  <StyledAlert onClose={handleClose} severity={alertType}>
                    {message}
                  </StyledAlert>
                </StyledSnackbar>
                <form onSubmit={handleSave} autoComplete="off">
                  <Grid container spacing={2} pt={0}>
                    <Grid item xs={12} md={8}>
                      <Autocomplete
                        options={tableData} // Using tableData
                        getOptionLabel={(option) =>
                          `${option.employeeCode}  -  ${option.employeeFirstName} ${option.employeeLastName}`
                        }
                        value={tableData.find((item) => item.employeeCode === employeeCode) || null} // Match the selected option
                        onChange={(event, value) => {
                          setEmployeeCode(value ? value.employeeCode : ""); // Update employeeCode in state
                          setEmployeeId(value ? value.employeeId : "");
                          setCompanyId(value ? value.companyId : "");
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Employee Code"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        label={isEditMode ? "Re-create password" : "Create Password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        required
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    mt={2}
                  >
                    <StyledButton type="submit">Save</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer
              component={Paper}
              className={`ag-theme-alpine ${employeeStyles.mTable}`} // Apply the new class
            >
              <AgGridReact
                rowData={loginAccessData} // Pass the table data as rowData
                columnDefs={columnDefs} // Pass the column definitions
                pagination={true} // Enable pagination
                paginationPageSize={11} // Set the number of rows per page
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
AccessControl.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
