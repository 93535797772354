export const dsaMasterStyles = {
  root: {
    height: "100%",
    minHeight: "300px",
    maxHeight: "400px",
    marginTop: "10px",
    overflowY: "auto",
    padding: "15px",
    paddingBottom: "0px",
  },
  tableContainer: {
    height: "98%",
    minHeight: "420px",
    maxHeight: "800px",
    marginTop: "20px",
    minwidth: "100%",
  },
  girdClass: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  marginStyle: {
    marginTop: "20px",
  },
  selectClass: {
    height: "40px",
  },
  radioClass: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  labelClass: {
    backgroundColor: "#FFFFFF",
  },
  formStyle: {
    paddingTop: "10px",
  },
  divStyle: {
    position: "relative",
    width: "100%",
  },
  divStyle: {
    position: "relative",
    width: "100%",
  },
  radioStyle: {
    color: "#00897b",
    "&.Mui-checked": {
      color: "#00897b",
    },
    "& .MuiSvgIcon-root": {
      border: "1px solid #00897b",
      borderRadius: "50%",
    },
  },
};
