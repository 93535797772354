import { useState } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import { Collapse } from "@mui/material";
import Link from "@mui/material/Link";
import { NavLink, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Padding } from "@mui/icons-material";

function SidenavDropdown({ routeItem }) {
  const { type, name, icon, title, noCollapse, collapse, key, href, route } = routeItem;
  const [open, setOpen] = useState({});
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const handleClick = (key) => {
    setOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderCollapse = (items, depth = 0) => {
    return items.map((item) => {
      const {
        name: itemName,
        icon: itemIcon,
        collapse: itemCollapse,
        route: itemRoute,
        key,
      } = item;
      const hasCollapse = itemCollapse && itemCollapse.length > 0;

      return (
        <MDBox key={key} ml={depth * 2.5}>
          {hasCollapse ? (
            <>
              <SidenavCollapse
                name={itemName}
                icon={
                  <>
                    {itemIcon}
                    {open[key] ? (
                      <Icon color="white" fontSize="small">
                        expand_less_icon
                      </Icon>
                    ) : (
                      <Icon color="white" fontSize="small">
                        expand_more_icon
                      </Icon>
                    )}
                  </>
                }
                active={key === collapseName}
                noCollapse={noCollapse}
                onClick={() => handleClick(key)}
                sx={{ fontSize: "12px" }} // Adjusted font size
              />
              <Collapse in={open[key]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderCollapse(itemCollapse, depth + 1)}
                </List>
              </Collapse>
            </>
          ) : (
            <NavLink to={itemRoute} style={{ textDecoration: "none" }}>
              <SidenavCollapse
                name={itemName}
                icon={itemIcon}
                active={key === collapseName}
                noCollapse={noCollapse}
                sx={{ fontSize: "12px" }} // Adjusted font size
              />
            </NavLink>
          )}
        </MDBox>
      );
    });
  };

  return (
    <>
      <SidenavCollapse
        name={name}
        icon={
          <>
            {icon}
            {/* {open[name] ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />} */}
          </>
        }
        active={key === collapseName}
        noCollapse={false}
        onClick={() => handleClick(name)}
        sx={{ fontSize: "12px" }} // Adjusted font size
      />
      <Collapse in={open[name]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderCollapse(collapse)}
        </List>
      </Collapse>
    </>
  );
}

SidenavDropdown.propTypes = {
  routeItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    collapse: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string, // Optional, specify as .isRequired if needed
    title: PropTypes.string,
    noCollapse: PropTypes.bool,
    key: PropTypes.string,
    href: PropTypes.string,
    route: PropTypes.string,
  }).isRequired,
};
export default SidenavDropdown;
