import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTheme } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
  CustomTableCell,
} from "components/StyledComponents/styledComponents.js";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { entryStyles } from "components/Styles/loanEntryStyles";
import {
  selectScrollStyle,
  tableCellStyle,
  CustomPaper,
  tableShadow,
  autoSelectStyle,
  autoSelectStyle2,
  globalStyles,
} from "components/Styles/globalStyles";
import { voucherMasterStyles } from "components/Styles/voucherStyles";
import AddIcon from "@mui/icons-material/Add";
import { useAccountsApi } from "customApiHooks/accountsApi";

export default function ContraVoucher() {
  const [selectedData, setSelectedData] = useState();
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedNarration, setNarration] = useState("");
  const [voucherNo, setVoucherNo] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [voucherToDelete, setVoucherToDelete] = useState(null);
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null);
  const [SelectCompany, setSelectCompany] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
  const [Company, SelectedCompany] = useState("");
  const [fromDate, setFromDate] = useState(new Date().toISOString().split("T")[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [seachTypeForGet, setSeachTypeForGet] = useState("CNT");
  const theme = useTheme();
  const MInput = inputStyle();
  const [rows, setRows] = useState([{ particular: "", amount: "" }]);
  const [totalAmount, setTotalAmount] = useState(0);
  const resetForm = () => {
    setSelectedAccount("");
    setVoucherNo("");
    setSelectedAccountName("");
    setNarration("");
    setSelectCompany("");
    setSelectedDate(new Date().toISOString().split("T")[0]);
    setRows([{ particular: "", amount: "" }]);
    setTotalAmount(0);
  };
  const resetFormState = () => {
    resetForm();
    setIsEditMode(false);
  };
  const {
    companies,
    accounts,
    paymentAccounts,
    tableData,
    voucherData,
    isEditMode,
    open,
    message,
    alertType,
    setOpen,
    getVoucherApi,
    manageVouchersApi,
    fetchListOfVouchers,
    handleDeleteVoucher,
    setIsEditMode,
    loading,
    showAlert,
    setLoading,
    handleCloseAlert,
  } = useAccountsApi(resetFormState);
  useEffect(() => {
    if (companies.length > 0) {
      const compId = companies[0]?.companyId; // Ensure companyId exists
      SelectedCompany(compId);
      const payload = {
        companyId: Company || compId,
        fromDate: fromDate || "2024-10-01",
        toDate: toDate || "2024-10-25",
        searchType: "CNT",
      };
      fetchListOfVouchers(payload);
    }
  }, [companies]);
  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    let rowData = null;
    if (props.api && props.api.getDisplayedRowAtIndex(rowIndex)) {
      rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    } else {
      console.error("API or row data is not available.");
    }
    const handleEdit = () => {
      handleEditVoucher(rowData.voucherID, rowData.companyId);
    };

    ActionCellRenderer.propTypes = {
      data: PropTypes.shape({
        voucherID: PropTypes.number.isRequired,
        Date: PropTypes.string.isRequired,
        VoucherNo: PropTypes.string.isRequired,
        Particulars: PropTypes.string,
        Amount: PropTypes.number.isRequired,
      }).isRequired,
    };

    return (
      <div>
        <ViewButton type="button" onClick={handleEdit}>
          <EditIcon />
        </ViewButton>
        <DeleteButton
          type="button"
          onClick={() => {
            setVoucherToDelete(rowData.voucherID);
            setCompanyIdToDelete(rowData.companyId);
            setOpenDialog(true);
          }}
        >
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };
  const columnDefs = [
    { headerName: "Actions", cellRenderer: ActionCellRenderer, minWidth: 100, flex: 1 },
    {
      headerName: "Date",
      field: "Date",
      filter: true,
      sorted: true,
      minWidth: 140,
      flex: 1,
      valueFormatter: (params) => {
        if (!params.value) return ""; // Handle null/undefined values
        const date = new Date(params.value);
        const day = date.getDate().toString().padStart(2, "0");
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      headerName: "Voucher No",
      field: "VoucherNo",
      filter: true,
      sorted: true,
      minWidth: 80,
      flex: 1,
      cellRenderer: (params) => {
        return <span>{params.value || "No Vouchers"}</span>;
      },
    },
    {
      headerName: "Particulars",
      field: "Particulars",
      minWidth: 110,
      flex: 1,
      filter: true,
      sorted: true,
      cellRenderer: (params) => {
        return <span>{params.value || "No Particulars"}</span>;
      },
    },
    {
      headerName: "Amount",
      field: "Amount",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
  ];
  const handleRowChange = (index, field, value) => {
    if (field === "particular") {
      // Check for duplicate account selection
      const isDuplicate = rows.some((row, i) => i !== index && row[field] === value);
      if (isDuplicate) {
        showAlert(`Account already exists in particulars`, "error");
        return; // Stop further execution if duplicate is found
      }
    }
    let updatedValue = value;
    if (field === "amount") {
      // Ensure only numeric input for the amount field
      updatedValue = value.replace(/[^0-9.]/g, "");
    }
    // Update the rows
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: updatedValue } : row
    );
    setRows(updatedRows);
    // Recalculate total amount if the field is "amount"
    if (field === "amount") calculateTotal(updatedRows);
  };
  // const formattedAmount = amount;
  const calculateTotal = (updatedRows) => {
    const total = updatedRows.reduce((acc, row) => acc + Number(row.amount || 0), 0);
    setTotalAmount(total); // Update totalAmount to be the sum of debit items
  };
  useEffect(() => {
    const selected = accounts.find((item) => item.accountID === selectedAccount);
    setSelectedAccountName(selected ? selected.accountName : "");
  }, [selectedAccount]);
  const handleEditVoucher = async (vid, cid) => {
    try {
      setIsEditMode(true);

      const payload = {
        voucherID: vid,
        companyID: cid,
      };

      // Wait for the API response
      const data = await getVoucherApi(payload);

      if (data) {
        setSelectedData(data);
        setSelectCompany(data.companyID);
        setVoucherNo(data.voucherClientID);
        setSelectedDate(data.entryDate?.split("T")[0] || ""); // Safely access entryDate
        setNarration(data.narration || "");

        // Populate debit items in `rows` for the particulars dropdown
        const debitRows = (data.debitItems || []).map((item) => ({
          particular: item.accountID,
          amount: item.amount,
          accountName: item.accountName,
          accountType: item.accountType,
        }));
        setRows(debitRows);
        calculateTotal(debitRows);

        if (data.creditItems?.length > 0) {
          setSelectedAccount(data.creditItems[0].accountID); // Assumes one credit item
        }

        if (data.PrintFormat) {
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(data.PrintFormat);
          newWindow.document.close();
        }
        setIsEditMode(true);
      }
    } catch (error) {
      showAlert("Error in handleEditVoucher:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const duplicateAccountFound = rows.some((row) => row.particular === selectedAccount);
    if (duplicateAccountFound) {
      showAlert("Accounts should be unique", "error");
      return; // Stop further execution if duplicate accounts are found
    }
    // Calculate the total amount for debit items
    const creditTotal = totalAmount;

    // Check if debit total equals the credit total
    if (totalAmount !== creditTotal) {
      showAlert("Error: Debit and Credit totals must be equal.", "error");
      return;
    }

    if (isEditMode) {
      fetchSaveVoucher("UPDATE", selectedData.voucherID);
    } else {
      fetchSaveVoucher("ADD");
    }
  };
  const fetchSaveVoucher = async (action = "ADD", voucherID = "0") => {
    const requestType = action.toUpperCase();
    const payload = {
      RequestType: requestType,
      voucherID,
      VoucherClientID: voucherNo,
      companyID: SelectCompany || 1189,
      VoucherType: "CNT",
      yearNo: 1,
      entryDate: selectedDate,
      paymentMode: "",
      paymentReference: "",
      narration: selectedNarration,
      CreateUserID: "ADMINL1",
      DebitItems: rows.map((row) => ({
        AccountType: "DR",
        AccountID: row.particular,
        Amount: row.amount,
      })),
      CreditItems: [
        {
          AccountType: "CR",
          AccountID: selectedAccount,
          Amount: totalAmount,
        },
      ],
    };
    manageVouchersApi(payload, fromDate, toDate, Company, seachTypeForGet);
  };
  const handleAddItem = (event) => {
    setRows((prevRows) => [...prevRows, { particular: "", amount: "" }]);
  };
  const handleRemoveItem = (index) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.filter((_, i) => i !== index);
      const finalRows = updatedRows.length ? updatedRows : [{ particular: "", amount: "" }];
      calculateTotal(finalRows); // Call calculateTotal with the updated rows
      return finalRows;
    });
  };
  const fetchVoucherData = async () => {
    const payload = {
      companyId: Company || companies.companyId,
      fromDate: fromDate || "2024-10-01",
      toDate: toDate || "2024-10-25",
      searchType: "CNT",
    };
    fetchListOfVouchers(payload);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TableContainer
              sx={{ ...tableShadow, ...voucherMasterStyles.paymentVoucher }}
              component={Paper}
            >
              <div style={entryStyles.divStyle}>
                <StyledTypography>
                  {!isEditMode ? "Create Contra Voucher" : "Edit Voucher Details"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetFormState}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <StyledSnackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                severity={alertType}
              >
                <StyledAlert onClose={handleClose} severity={alertType}>
                  {message}
                </StyledAlert>
              </StyledSnackbar>
              <Dialog
                onClose={() => setLoading(false)}
                open={loading}
                aria-labelledby="loading-dialog-title"
              >
                <DialogContent
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                  <CircularProgress color="primary" />
                  <Typography variant="h6" style={{ marginTop: "20px" }}>
                    Loading, please wait...
                  </Typography>
                </DialogContent>
              </Dialog>
              <form autoComplete="off">
                <MDBox pt={3} pb={3} px={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={2.5}>
                      <TextField
                        fullWidth
                        label="Date"
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4.75}>
                      <Autocomplete
                        options={companies}
                        getOptionLabel={(option) => option.companyName || ""}
                        value={
                          SelectCompany
                            ? companies.find((item) => item.companyId === SelectCompany)
                            : null
                        }
                        onChange={(event, newValue) => {
                          setSelectCompany(newValue ? newValue.companyId : "");
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Select Company"
                            variant="outlined"
                            required
                            disableClearable={false}
                            filterOptions={(options, state) => {
                              const input = state.inputValue.toLowerCase();

                              // Filter options that start with the input value
                              const startsWithInput = options.filter((option) =>
                                option.companyName.toLowerCase().startsWith(input)
                              );

                              // Filter options that contain the input value but do not start with it
                              const containsInput = options.filter(
                                (option) =>
                                  !option.companyName.toLowerCase().startsWith(input) &&
                                  option.companyName.toLowerCase().includes(input)
                              );

                              // Combine the options: ones that start with the input first, then the rest
                              return [...startsWithInput, ...containsInput];
                            }}
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4.75}>
                      <Autocomplete
                        options={accounts}
                        getOptionLabel={(option) => option.accountName || ""}
                        value={
                          selectedAccount
                            ? accounts.find((item) => item.accountID === selectedAccount)
                            : null
                        }
                        onChange={(event, newValue) => {
                          setSelectedAccount(newValue ? newValue.accountID : "");
                        }}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Filter options that start with the input value
                          const startsWithInput = options.filter((option) =>
                            option.accountName.toLowerCase().startsWith(input)
                          );

                          // Filter options that contain the input value but do not start with it
                          const containsInput = options.filter(
                            (option) =>
                              !option.accountName.toLowerCase().startsWith(input) &&
                              option.accountName.toLowerCase().includes(input)
                          );

                          // Combine the options: starts with input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Select Account"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {isEditMode && (
                      <Grid item xs={4}>
                        <TextField
                          label="Voucher No"
                          value={voucherNo}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
                <TableContainer
                  component={Paper}
                  sx={voucherMasterStyles.tableContainerShadowStyle}
                >
                  <Table>
                    <TableBody>
                      <TableRow sx={voucherMasterStyles.tableHead}>
                        <CustomTableCell
                          sx={{ flex: 1, minWidth: "200px", maxWidth: "400px" }}
                          fontWeight="bold"
                          align="center"
                        >
                          Particulars
                        </CustomTableCell>
                        <CustomTableCell
                          sx={{ minWidth: "150px", width: "200px" }}
                          fontWeight="bold"
                          align="center"
                        >
                          Amount
                        </CustomTableCell>
                        <CustomTableCell
                          sx={{ minWidth: "120px", width: "180px" }}
                          fontWeight="bold"
                          align="center"
                        >
                          Add / Remove
                        </CustomTableCell>
                      </TableRow>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Grid item xs={12}>
                              <Autocomplete
                                options={accounts}
                                getOptionLabel={(option) => option.accountName}
                                value={
                                  accounts.find((item) => item.accountID === row.particular) || null
                                }
                                onChange={(event, value) =>
                                  handleRowChange(index, "particular", value ? value.accountID : "")
                                }
                                mt={10}
                                PaperComponent={CustomPaper}
                                disableClearable={false}
                                filterOptions={(options, state) => {
                                  const input = state.inputValue.toLowerCase();

                                  // Filter options that start with the input value
                                  const startsWithInput = options.filter((option) =>
                                    option.accountName.toLowerCase().startsWith(input)
                                  );

                                  // Filter options that contain the input value but do not start with it
                                  const containsInput = options.filter(
                                    (option) =>
                                      !option.accountName.toLowerCase().startsWith(input) &&
                                      option.accountName.toLowerCase().includes(input)
                                  );

                                  // Combine the options: starts with input first, then the rest
                                  return [...startsWithInput, ...containsInput];
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Account"
                                    variant="outlined"
                                    sx={{
                                      height: 43,
                                      ...MInput.b,
                                      ...voucherMasterStyles.selectClass,
                                      "& .MuiInputBase-root": {
                                        height: "100%",
                                        minHeight: 43,
                                      },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: MInput.a, // Ensure this applies to the label
                                        "&.Mui-focused": {
                                          color: "red", // Focused label color
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label=""
                                // type="number"
                                value={row.amount}
                                onChange={(e) => handleRowChange(index, "amount", e.target.value)}
                                sx={{ ...MInput.b, ...voucherMasterStyles.amountFeild }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">₹</InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  sx: {
                                    color: MInput.a,
                                  },
                                }}
                              />
                            </Grid>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "100px", width: "120px" }}>
                            <ViewButton type="button" onClick={handleAddItem}>
                              <AddIcon />
                            </ViewButton>
                            <DeleteButton type="button" onClick={() => handleRemoveItem(index)}>
                              <DeleteIcon />
                            </DeleteButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <CustomTableCell
                          minWidth="100px"
                          flexGrow={1}
                          fontWeight="bold"
                          align="center"
                          colSpan={1}
                        >
                          Total Amount
                        </CustomTableCell>
                        <CustomTableCell
                          minWidth="100px"
                          flexGrow={1}
                          fontWeight="bold"
                          align="center"
                          colSpan={3}
                        >
                          <strong>
                            {`₹ ${totalAmount.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}
                          </strong>
                        </CustomTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid item xs={12} mt={2} p={2} md={12}>
                  <TextField
                    label="Narration"
                    fullWidth
                    multiline
                    rows={2}
                    value={selectedNarration}
                    onChange={(e) => setNarration(e.target.value)}
                  />
                </Grid>

                <MDBox pt={2} px={1} pr={4}>
                  <Grid container justifyContent="flex-end">
                    <StyledButton type="button" onClick={() => handleSave()}>
                      {isEditMode ? "Update" : "Save"}
                    </StyledButton>
                  </Grid>
                </MDBox>
              </form>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <TableContainer
              // sx={{ ...tableShadow }}
              component={Paper}
              sx={voucherMasterStyles.tableContainertwoStyle}
            >
              <MDBox sx={voucherMasterStyles.mdBoxStyle}>
                <MDBox mt={1} mb={2} sx={voucherMasterStyles.subMdBoxStyle}>
                  <TextField
                    label="From Date"
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    sx={{ ...MInput.b, ...voucherMasterStyles.textFieldClass }}
                    InputLabelProps={{
                      sx: {
                        color: MInput.a,
                      },
                    }}
                  />
                  <TextField
                    label="To Date"
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    sx={{ ...voucherMasterStyles.textFieldClass, ...MInput.b }}
                    InputLabelProps={{
                      sx: {
                        color: MInput.a,
                      },
                    }}
                  />
                  <Autocomplete
                    options={companies}
                    getOptionLabel={(option) => option.companyName || ""}
                    value={Company ? companies.find((item) => item.companyId === Company) : null}
                    onChange={(event, newValue) => {
                      SelectedCompany(newValue ? newValue.companyId : "");
                    }}
                    PaperComponent={CustomPaper}
                    disableClearable={false}
                    filterOptions={(options, state) => {
                      const input = state.inputValue.toLowerCase();

                      // Filter options that start with the input value
                      const startsWithInput = options.filter((option) =>
                        option.companyName.toLowerCase().startsWith(input)
                      );

                      // Filter options that contain the input value but do not start with it
                      const containsInput = options.filter(
                        (option) =>
                          !option.companyName.toLowerCase().startsWith(input) &&
                          option.companyName.toLowerCase().includes(input)
                      );

                      // Combine the options: ones that start with the input first, then the rest
                      return [...startsWithInput, ...containsInput];
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Select Company"
                        variant="outlined"
                        required
                        sx={{
                          height: 43,
                          ...autoSelectStyle2,
                          ...MInput.b,
                          "& .MuiInputBase-root": {
                            height: "100%",
                            minHeight: 43,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a, // Ensure this applies to the label
                            "&.Mui-focused": {
                              color: "red", // Focused label color
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <StyledButton
                    type="button"
                    onClick={fetchVoucherData}
                    sx={voucherMasterStyles.buttonFieldClass}
                  >
                    Display
                  </StyledButton>
                </MDBox>
              </MDBox>
              <StyledAgGridContainer
                component={Paper}
                sx={{ ...tableShadow, flexGrow: 1 }}
                className={`ag-theme-alpine`}
              >
                <AgGridReact
                  rowData={tableData}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationPageSize={10}
                />
              </StyledAgGridContainer>
            </TableContainer>
          </Grid>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogContent>
              <Typography>Are you sure you want to delete this voucher?</Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={() => {
                  if (voucherToDelete && companyIdToDelete) {
                    handleDeleteVoucher(
                      voucherToDelete,
                      companyIdToDelete,
                      fromDate,
                      toDate,
                      Company
                    );
                  }
                  setOpenDialog(false); // Close the dialog after deleting
                }}
              >
                Yes
              </StyledButton>
              <StyledButton onClick={() => setOpenDialog(false)}>No</StyledButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
ContraVoucher.propTypes = {
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
