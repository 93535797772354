import Dashboard from "layouts/dashboard";
import InsuranceCommencement from "components/Forms/Business Approval/InsuranceCommencement";
import LoanDisbursement from "components/Forms/Business Approval/LoanDisbursement";
import MutualFundCommencement from "components/Forms/Business Approval/MutualFundCommencement";
import InsuranceEntry from "components/Forms/BusinessEntry/InsuranceEntry";
import LoanEntry from "components/Forms/BusinessEntry/LoanEntry";
import MonthEnd from "components/Forms/BusinessEntry/MonthEnd";
import MutualFundEntry from "components/Forms/BusinessEntry/MutualFundEntry";
import BankerMaster from "components/Forms/BusinessMasters/BankerMaster";
import DsaMaster from "components/Forms/BusinessMasters/DsaMaster";
import InsuranceMaster from "components/Forms/BusinessMasters/Insurance";
import MutualFunds from "components/Forms/BusinessMasters/MutualFunds";
import CompanyConfiguration from "components/Forms/Masters/CompanyConfiguration";
import EmployeeMaster from "components/Forms/Masters/EmployeeMaster";
import AccountHeadCreation from "components/Accounts/Masters/AccountHeadCreation";
import GroupCreation from "components/Accounts/Masters/GroupCreation";
import AccountsPayable from "components/Accounts/Reports/AccountsPayable";
import Ledger from "components/Accounts/Reports/Ledger";
import ContraVoucher from "components/Accounts/VoucherEntry/ContraVoucher";
import JurnalVoucher from "components/Accounts/VoucherEntry/JournalVoucher";
import PaymentVoucher from "components/Accounts/VoucherEntry/PaymentVoucher";
import RecieptVoucher from "components/Accounts/VoucherEntry/ReceiptVoucher";
import EmployeeProfitability from "components/Accounts/MISReports/EmployeeProfitability";
import MonthlyBusiness from "components/Accounts/MISReports/MonthlyBusiness";
import BankerPayoutReceipt from "components/Forms/Business Approval/BankerPayoutReceipt";
import DsaPayoutReceipt from "components/Forms/Business Approval/DsaPayoutReceipt";
import MonthlyDSABusiness from "components/Accounts/MISReports/MonthlyDsaBusiness";
import MonthlyEmployeeBusiness from "components/Accounts/MISReports/MonthlyEmployeeBusiness";
import SubDsaPayoutReceipt from "components/Forms/Business Approval/SubDsaPayoutReceipt";
import AccountsReceivable from "components/Accounts/Reports/AccountsReceivable";
import EntryAdvance from "components/Forms/BusinessEntry/EntryAdvance";
import AccessControl from "components/Forms/Masters/AccessControl";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "dropdown",
    name: "Business Forms",
    key: "forms",
    icon: <Icon fontSize="small">assignment</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Business Masters",
        key: "business-masters",
        // icon: <Icon fontSize="small">arrow_drop_down</Icon>,
        collapse: [
          {
            type: "collapse",
            name: "Company Configs",
            key: "/forms/company-configuration-form",
            route: "/forms/company-configuration-form",
            icon: <Icon fontSize="small">business_center</Icon>,
            component: <CompanyConfiguration />,
          },
          {
            type: "collapse",
            name: "Accces Control",
            key: "/forms/access-control-form",
            route: "/forms/access-control-form",
            icon: <Icon fontSize="small">lock_person_icon</Icon>,
            component: <AccessControl />,
          },
          {
            type: "collapse",
            name: "Employee Master",
            key: "/forms/employee-master-form",
            route: "/forms/employee-master-form",
            icon: <Icon fontSize="small">group_icon</Icon>,
            component: <EmployeeMaster />,
          },
          {
            type: "collapse",
            name: "DSA Master",
            key: "/forms/dsa-master-form",
            route: "/forms/dsa-master-form",
            icon: <Icon fontSize="small">work</Icon>,
            component: <DsaMaster />,
          },
          {
            name: "Banker Master",
            key: "banker-master",
            route: "/forms/banker-master-form",
            icon: <Icon fontSize="small">assured_workload</Icon>,
            component: <BankerMaster />,
          },
          {
            name: "Insurance Master",
            key: "insurance-master",
            route: "/forms/insurance-master-form",
            icon: <Icon fontSize="small">security</Icon>, // Replaced with "security" icon
            component: <InsuranceMaster />,
          },
          {
            name: "Mutual Funds",
            key: "/forms/mutual-funds-form",
            route: "/forms/mutual-funds-form",
            icon: <Icon fontSize="small">bar_chart</Icon>, // Replaced with "bar_chart" icon
            component: <MutualFunds />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Business Entry",
        key: "business-entry",
        // icon: <Icon fontSize="small">arrow_drop_down</Icon>,
        collapse: [
          {
            name: "Loan Entry",
            key: "/forms/loan-entry-form",
            route: "/forms/loan-entry-form",
            icon: <Icon fontSize="small">credit_card</Icon>,
            component: <LoanEntry />,
          },
          {
            name: "Insurance Entry",
            key: "/forms/insurance-entry-form",
            route: "/forms/insurance-entry-form",
            icon: <Icon fontSize="small">health_and_safety</Icon>,
            component: <InsuranceEntry />,
          },
          {
            name: "Mutual Fund Entry",
            key: "/forms/mutual-fund-entry-form",
            route: "/forms/mutual-fund-entry-form",
            icon: <Icon fontSize="small">trending_up_icon</Icon>,
            component: <MutualFundEntry />,
          },
          {
            name: "Business Advance",
            key: "/forms/business-advance",
            route: "/forms/business-advance",
            icon: <Icon fontSize="small">currency_rupee</Icon>,
            component: <EntryAdvance />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Business Approval",
        key: "business-approval",
        collapse: [
          {
            name: "Loan Approval",
            key: "/forms/loan-disbursement-form",
            route: "/forms/loan-disbursement-form",
            icon: <Icon fontSize="small">assignment_turned_in</Icon>,
            component: <LoanDisbursement />,
          },
          {
            name: "Insurance Approval",
            key: "/forms/insurance-commencement-form",
            route: "/forms/insurance-commencement-form",
            icon: <Icon fontSize="small">assignment_turned_in</Icon>,
            component: <InsuranceCommencement />,
          },
          {
            name: "Mutual Funds Approval",
            key: "/forms/mutual-fund-commencement-form",
            route: "/forms/mutual-fund-commencement-form",
            icon: <Icon fontSize="small">assignment_turned_in</Icon>,
            component: <MutualFundCommencement />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Payouts",
        key: "payouts",
        // icon: <Icon fontSize="small">currency_rupee</Icon>,
        collapse: [
          {
            name: "Banker Payout Receipt",
            key: "/forms/banker-payout-receipt-form",
            route: "/forms/banker-payout-receipt-form",
            icon: <Icon fontSize="small">currency_rupee</Icon>,
            component: <BankerPayoutReceipt />,
          },
          {
            name: "Dsa Payout Receipt",
            key: "/forms/dsa-payout-receipt-form",
            route: "/forms/dsa-payout-receipt-form",
            icon: <Icon fontSize="small">currency_rupee</Icon>,
            component: <DsaPayoutReceipt />,
          },
          {
            name: "Sub Dsa Payout Receipt",
            key: "/forms/sub-dsa-payout-receipt-form",
            route: "/forms/sub-dsa-payout-receipt-form",
            icon: <Icon fontSize="small">currency_rupee</Icon>,
            component: <SubDsaPayoutReceipt />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Month End",
    key: "month-end",
    route: "/month-end",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    component: <MonthEnd />,
  },
  {
    type: "dropdown",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">account_balance</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Masters",
        key: "masters",
        // icon: <Icon fontSize="small">arrow_drop_down</Icon>,
        collapse: [
          {
            name: "Group Creation",
            key: "/accounts/group-creation-form",
            route: "/accounts/group-creation-form",
            icon: <Icon fontSize="small">arrow_forward</Icon>, // Replaced with a right arrow icon
            component: <GroupCreation />,
          },

          {
            name: "Account Head Creation",
            key: "/accounts/account-head-creation",
            route: "/accounts/account-head-creation",
            icon: <Icon fontSize="small">arrow_forward</Icon>,
            component: <AccountHeadCreation />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Voucher Entry",
        key: "voucher-entry",
        // icon: <Icon fontSize="small">arrow_drop_down</Icon>,
        collapse: [
          {
            name: "Payment Voucher",
            key: "/accounts/payment-voucher",
            route: "/accounts/payment-voucher",
            icon: <Icon fontSize="small">receipt</Icon>, // Represents payment transactions
            component: <PaymentVoucher />,
          },
          {
            name: "Receipt Voucher",
            key: "/accounts/receipt-voucher",
            route: "/accounts/receipt-voucher",
            icon: <Icon fontSize="small">receipt</Icon>, // Represents receipts or payment records
            component: <RecieptVoucher />,
          },
          {
            name: "Contra Voucher",
            key: "/accounts/contra-voucher",
            route: "/accounts/contra-voucher",
            icon: <Icon fontSize="small">receipt</Icon>, // Represents dual transactions (e.g., debit and credit)
            component: <ContraVoucher />,
          },
          {
            name: "Journal Voucher",
            key: "/accounts/journal-voucher",
            route: "/accounts/journal-voucher",
            icon: <Icon fontSize="small">receipt</Icon>, // Represents accounting journals or records
            component: <JurnalVoucher />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Reports",
        key: "reports",
        // icon: <Icon fontSize="small">arrow_drop_down</Icon>,
        collapse: [
          {
            name: "Ledger",
            key: "/accounts/ledger",
            route: "/accounts/ledger",
            icon: <Icon fontSize="small">account_balance_wallet</Icon>, // Represents financial accounts and ledgers
            component: <Ledger />,
          },
          {
            name: "Accounts Payable",
            key: "/accounts/accounts-payable",
            route: "/accounts/accounts-payable",
            icon: <Icon fontSize="small">arrow_forward</Icon>, // Represents outgoing payments and liabilities
            component: <AccountsPayable />,
          },
          {
            name: "Accounts Receivable",
            key: "/accounts/accounts-receivable",
            route: "/accounts/accounts-receivable",
            icon: <Icon fontSize="small">arrow_forward</Icon>, // Represents financial transactions involving currency
            component: <AccountsReceivable />,
          },
        ],
      },
      {
        type: "dropdown",
        name: "MIS Reports",
        key: "MIS reports",
        collapse: [
          {
            name: "Employee Profitability",
            key: "/MIS-reports/employee-profitability",
            route: "/MIS-reports/employee-profitability",
            icon: <Icon fontSize="small">insights</Icon>, // Represents analytics and profitability insights
            component: <EmployeeProfitability />,
          },
          {
            name: "Monthly Business",
            key: "/MIS-reports/monthly-business",
            route: "/MIS-reports/monthly-business",
            icon: <Icon fontSize="small">insert_chart_outlined</Icon>, // Changed to insert_chart_outlined for a graph/chart look
            component: <MonthlyBusiness />,
          },

          {
            name: "DSA Business",
            key: "/MIS-reports/monthly-dsa-business",
            route: "/MIS-reports/monthly-dsa-business",
            icon: <Icon fontSize="small">groups</Icon>, // Changed to groups for collaboration/network
            component: <MonthlyDSABusiness />,
          },

          {
            name: "Employee Business",
            key: "/MIS-reports/monthly-employee-business",
            route: "/MIS-reports/monthly-employee-business",
            icon: <Icon fontSize="small">work_outline</Icon>, // Represents employee-related activities and business contributions
            component: <MonthlyEmployeeBusiness />,
          },
        ],
      },
    ],
  },
  {
    type: "divider",
  },
];

export default routes;
