export const accountHeadStyles = {
  root: {
    minHeight: "40px",
    maxHeight: "500px",
    marginTop: "10px",
    overflowY: "auto",
    padding: "15px",
    minWidth: "50%",
    paddingBottom: "20px",
  },
  buttonGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  girdClass: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectClass: {
    height: "40px",
  },
  labelClass: {
    backgroundColor: "#FFFFFF",
  },
};
