export const employeeStyles = {
  root: {
    height: "auto", // Adjusts to the content's height
    minHeight: "100px", // Sets a minimum height
    marginTop: "10px",
    padding: "15px",
    marginBottom: "17px",
  },
  mTable: {
    height: "100%",
    minHeight: "300px",
    flexGrow: 1,
  },
  mainTable: {
    display: "flex",
    width: "100%",

    borderRadius: "10px",
    overflow: "hidden",
    height: "calc(100vh - 16.5vh)",
    minHeight: "400px",
    maxHeight: "800px",
    padding: "16px",
    marginTop: "16px",
    // Ensure rounded edges
    overflow: "hidden",
    background: "#fff", // Optional for visibility
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "4px", // Matches container rounding
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  tableContainer: {
    height: "98%",
    minHeight: "420px",
    maxHeight: "800px",
    marginTop: "20px",
    margin: "0", // Ensure no extra margin
    padding: "0", // Ensure no extra padding
    minWidth: "100%",
    borderRadius: "10px", // Add rounded corners
    overflow: "hidden",
  },
  girdClass: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectClass: {
    height: "44px",
  },
  autoSelectClass: {
    height: "11px",
  },
  labelClass: {
    backgroundColor: "#FFFFFF",
  },
};
