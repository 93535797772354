import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import URLs from "constants/urls";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import {
  TableContainer,
  Paper,
  Grid,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  inputStyle,
  StyledSnackbar,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents";
import { accountHeadStyles } from "components/Styles/accountHeadCreation";
import {
  selectScrollStyle,
  tableCellStyle,
  CustomPaper,
  tableShadow,
  boxShadow,
  globalStyles,
} from "components/Styles/globalStyles";

export default function AccountHeadCreation() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [parentGroups, setParentGroups] = useState([]);
  const [selectedSubGroupName, setSelectedSubGroupName] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [selectedMainGroup, setSelectedMainGroup] = useState("");
  const [remarks, setRemarks] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const MInput = inputStyle();
  const [openingValue, setOpeningValue] = useState("");
  const [deleteAccountId, setDeleteAccountId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setOpeningValue(event.target.value);
  };
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  useEffect(() => {
    const fetchAllGroups = async () => {
      setLoading(true);
      try {
        const response = await fetch(URLs.AllGroupUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) throw new Error("Failed to fetch groups");
        const data = await response.json();

        // Map API data to use `groupName` as mainGroup by setting `parentGroupName` as `groupName`
        const mappedData = data.map((group) => ({
          groupId: group.groupId,
          groupName: group.parentGroupName, // Changing parentGroupName to groupName for consistency
        }));

        setAllGroups(mappedData);
      } catch (error) {
        setAlert("Error fetching groups: " + error.message, "error");
      }
      setLoading(false);
    };

    fetchAllGroups();
    fetchParentGroups();
    fetchAccounts();
  }, []);
  async function fetchParentGroups() {
    try {
      setLoading(true);
      const response = await fetch(URLs.ParentGroupUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch parent groups");
      const data = await response.json();
      setParentGroups(data);
    } catch (error) {
      setAlert("Error fetching parent groups: " + error.message, "error");
    }
    setLoading(false);
  }
  async function fetchAccounts() {
    try {
      setLoading(true);
      const response = await fetch(URLs.GetAccountsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setAlert("Error fetching accounts: " + error.message, "error");
    }
    setLoading(false);
  }

  const handleSave = async (event) => {
    event.preventDefault();
    const newEntry = {
      accountID: selectedId || 0,
      accountName: accountName || "",
      groupId: selectedMainGroup || 0,
      groupName: selectedSubGroupName || "",
      remarks: remarks || "",
      activeStatus: "A",
      openingBalance: openingBalance,
      openingBalanceType: openingValue || "DR",
      CreateUserID: "USER 1",
      CreateDateTime: new Date().toISOString(),
      ModifiedUserID: "USER 1",
      ModifiedDateTime: new Date().toISOString(),
      RequestType: "ADD",
    };
    setLoading(true);
    try {
      const response = await fetch(URLs.PostAccountLedger, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newEntry),
      });

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText);
      }
      setAlert("Account saved successfully", "success");
      fetchAccounts();
      resetInputData();
    } catch (error) {
      setAlert(error.message, "error");
    }
    setLoading(false);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const updatedEntry = {
      RequestType: "UPDATE",
      accountID: selectedId,
      accountName: accountName || "",
      groupId: selectedMainGroup || 0,
      groupName: selectedSubGroupName || "",
      remarks: remarks || "",
      activeStatus: "A",
      openingBalance: openingBalance,
      openingBalanceType: openingValue || "DR",
      CreateUserID: "USER 1",
      CreateDateTime: new Date().toISOString(),
      ModifiedUserID: "USER 1",
      ModifiedDateTime: new Date().toISOString(),
    };
    setLoading(true);
    try {
      const response = await fetch(URLs.PostAccountLedger, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedEntry),
      });

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText || "Failed to update");
      }

      setAlert("Account updated successfully", "success");
      fetchAccounts();
      resetInputData();
    } catch (error) {
      setAlert(error.message, "error");
    }
    setLoading(false);
  };

  const handleDelete = (id, accountName) => {
    setDeleteAccountId(id); // Set the ID to delete
    setAccountName(accountName); // Set the name to show in dialog
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(URLs.PostAccountLedger, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ accountID: deleteAccountId, RequestType: "DELETE" }),
      });

      if (!response.ok) throw new Error("Failed to delete account");

      setAlert("Account deleted successfully", "success");
      fetchAccounts();
    } catch (error) {
      setAlert("Error deleting account: " + error.message, "error");
    }
    setOpenDialog(false); // Close the confirmation dialog
    setLoading(false);
  };

  const handleCancelDelete = () => {
    setDeleteAccountId(null); // Reset delete ID
    setOpenDialog(false); // Close the confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewClick = (rowData) => {
    console.log("edit data", rowData);
    setSelectedId(rowData.accountID);
    setAccountName(rowData.accountName || "");
    setSelectedMainGroup(rowData.groupId || "");
    setSelectedSubGroupName(rowData.groupName || "");
    setRemarks(rowData.remarks || "");
    setOpeningBalance(rowData.openingBalance || 0);
    setOpeningValue(rowData.openingBalanceType || "");
    setIsEditMode(true);
  };
  const resetInputData = () => {
    setSelectedId("");
    setAccountName("");
    setSelectedGroup("");
    setSelectedMainGroup("");
    setSelectedSubGroupName("");
    setRemarks("");
    setOpeningBalance("");
    setOpeningValue("");
    setIsEditMode(false);
  };

  const ActionCellRenderer = (props) => {
    const rowData = props.data;
    return (
      <div>
        <ViewButton onClick={() => handleViewClick(rowData)}>
          <EditIcon />
        </ViewButton>
        <DeleteButton onClick={() => handleDelete(rowData.accountID, rowData.accountName)}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };

  ActionCellRenderer.propTypes = {
    data: PropTypes.shape({
      accountID: PropTypes.number.isRequired,
      accountName: PropTypes.string,
      SelectGroup: PropTypes.string,
      Remarks: PropTypes.string,
      openingBalance: PropTypes.string,
    }).isRequired,
  };

  const columnDefs = [
    { headerName: "Actions", field: "actions", cellRenderer: ActionCellRenderer, flex: 1 },
    {
      headerName: "Account Name",
      field: "accountName",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
    },
    { headerName: "GroupName", field: "groupName", sortable: true, filter: true, flex: 1 },
    {
      headerName: "Opening Balance",
      field: "openingBalance",
      sortable: true,
      filter: true,
      flex: 1,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Grid container spacing={2}>
          <Dialog open={openDialog}>
            <DialogContent sx={subDialogContentStyle}>
              <DialogContentText sx={dialogContentTextStyle}>
                {`Are you sure you want to delete ${accountName}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={accountHeadStyles.dialogActions}>
              <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
              <StyledButton onClick={handleCancelDelete} variant="outlined">
                No
              </StyledButton>
            </DialogActions>
          </Dialog>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow }}>
              <div style={{ position: "relative", width: "100%" }}>
                <StyledTypography>
                  {!isEditMode ? "Account Head Creation" : "Edit Account Head"}
                </StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetInputData}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <Grid sx={accountHeadStyles.root}>
                <StyledSnackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <StyledAlert onClose={handleClose} severity={alertType}>
                    {message}
                  </StyledAlert>
                </StyledSnackbar>
                <form onSubmit={isEditMode ? handleUpdate : handleSave} autoComplete="off">
                  <Grid container spacing={2} pb={0}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        label="Account Name"
                        fullWidth
                        required
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        inputProps={{ maxLength: 120 }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>

                    {/* Dropdown for selecting group */}
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={parentGroups}
                        getOptionLabel={(option) => option.groupName || ""}
                        value={
                          selectedMainGroup
                            ? parentGroups.find((group) => group.groupId === selectedMainGroup)
                            : null
                        }
                        onChange={(event, newValue) => {
                          setSelectedMainGroup(newValue ? newValue.groupId : ""); // Add groupName here
                        }}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Filter options that start with the input value
                          const startsWithInput = options.filter((option) =>
                            option.groupName.toLowerCase().startsWith(input)
                          );

                          // Filter options that contain the input value but do not start with it
                          const containsInput = options.filter(
                            (option) =>
                              !option.groupName.toLowerCase().startsWith(input) &&
                              option.groupName.toLowerCase().includes(input)
                          );

                          // Combine the options: ones that start with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Main Group"
                            fullWidth
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Default label color
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        label="Opening Balance"
                        fullWidth
                        required
                        value={openingBalance}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d{0,2}$/.test(value)) {
                            setOpeningBalance(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 120,
                          inputMode: "decimal",
                        }}
                        sx={MInput.b}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ₹ {/* Currency symbol */}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Autocomplete
                        options={[
                          { value: "DR", label: "DR" },
                          { value: "CR", label: "CR" },
                        ]} // Options for Autocomplete
                        getOptionLabel={(option) => option.label} // Specify the label property
                        value={{ value: openingValue, label: openingValue }} // Pre-select based on current value
                        onChange={(event, value) =>
                          handleChange({ target: { value: value ? value.value : "" } })
                        } // Update state on selection
                        PaperComponent={CustomPaper} // Use your custom paper if applicable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Remarks"
                        fullWidth
                        multiline
                        rows={2} // Adjust rows to fit message input needs
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        inputProps={{ maxLength: 500 }} // Limit the message length
                        sx={MInput.b} // Custom styles
                        InputLabelProps={{
                          sx: {
                            color: MInput.a, // Custom label color
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={accountHeadStyles.buttonGrid}>
                    <StyledButton type="submit">{isEditMode ? "Update" : "Save"}</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={tableData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={11}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
