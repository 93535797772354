import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Destructure the data you need
import useDashboardData from "../../customApiHooks/dashboardApi";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  const date = new Date().toDateString();
  const getCurrentMonthName = () => new Date().toLocaleString("default", { month: "long" });
  const {
    monthlyLineChartData,
    barGraphMonthlyData,
    yearlyLineChartData,
    monthlyDsaBusinessData,
    monthlyWiseEmployeeBusiness,
    monthlyOverViewData,
    activeEmployees,
    alertType,
    message,
    loading,
    setAlert,
    handleClose,
  } = useDashboardData();
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // Calculate and format the values
  const business = formatter
    .format(
      monthlyOverViewData["IN"].business +
        monthlyOverViewData["BL"].business +
        monthlyOverViewData["MF"].business
    )
    .replace("₹", "₹ ");

  const revenueM = formatter
    .format(
      monthlyOverViewData["IN"].revenue +
        monthlyOverViewData["BL"].revenue +
        monthlyOverViewData["MF"].revenue
    )
    .replace("₹", "₹ ");

  const submissions =
    monthlyOverViewData["IN"].totalApplications +
    monthlyOverViewData["BL"].totalApplications +
    monthlyOverViewData["MF"].totalApplications;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} mt={0.25}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="assignment"
                title="Submissions"
                count={submissions}
                percentage={{
                  color: "success",
                  amount: "This Month’s Submissions",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="currency_rupee"
                title="Total Business"
                count={business}
                percentage={{
                  color: "success",
                  amount: "This Month’s Business",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="trending_up"
                title="Revenue"
                count={revenueM}
                percentage={{
                  color: "success",
                  amount: "This Month’s Revenue",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="group"
                title="Employees"
                count={activeEmployees}
                percentage={{
                  color: "main",
                  amount: "Active Workforce",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Month to Date Business"
                  threeLines="1"
                  description={<>{getCurrentMonthName()}&apos;s business</>}
                  date={date}
                  chart={monthlyLineChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Month to Date Business"
                  description={<>{getCurrentMonthName()}&apos;s business </>}
                  date={date}
                  chart={barGraphMonthlyData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Year to Date Bussiness"
                  threeLines="1"
                  description={<>{new Date().getFullYear()}&apos;s business</>}
                  date={date}
                  chart={yearlyLineChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Year to Date Employee Wise Business"
                  description={<>{getCurrentMonthName()}&apos;s Employee Wise Business</>}
                  date={date}
                  chart={monthlyWiseEmployeeBusiness}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Month to Date DSA wise Business"
                  description={<>{getCurrentMonthName()}&apos;s DSA Wise Business</>}
                  date={date}
                  chart={monthlyDsaBusinessData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview monthlyOverViewData={monthlyOverViewData} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
