import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie"; // Import js-cookie
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Box } from "@mui/material";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import { getBackgroundColor } from "components/StyledComponents/styledComponents";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import { StyledButtonL, StyledButtonLc } from "components/StyledComponents/styledComponents.js";

import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const bColor = getBackgroundColor(controller);
  const navigate = useNavigate();
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [employeeCode, setEmployeeCode] = useState(""); // State to store the employee code

  useEffect(() => {
    const username = Cookies.get("username");
    setEmployeeCode(username || "N/A");
  }, []);

  const handleProfileOpen = (event) => setProfileAnchor(event.currentTarget);
  const handleProfileClose = () => setProfileAnchor(null);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleLogout = () => {
    Cookies.remove("access_token");
    navigate("/login");
  };

  // Profile Menu Handlers

  const iconsStyle = ({ palette: { dark, white, text, info }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : info.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        borderBottom: `3px solid ${bColor}`,
        paddingBottom: "0px",
        backgroundColor: "white",
      })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { miniSidenav })}>
            <MDBox display="flex" alignItems="center">
              <StyledButtonLc
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon fontSize="medium"> {miniSidenav ? "menu_open" : "menu"}</Icon>
              </StyledButtonLc>
              <Tooltip
                title={<span style={{ fontSize: "14px", fontWeight: "bold" }}>Profile</span>}
                arrow
              >
                <StyledButtonLc
                  size=""
                  disableRipple
                  color="inherit"
                  sx={{
                    ...navbarIconButton,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleProfileOpen}
                >
                  <Icon>person</Icon>
                </StyledButtonLc>
              </Tooltip>
              <Menu
                anchorEl={profileAnchor}
                open={Boolean(profileAnchor)}
                onClose={handleProfileClose}
                keepMounted
                sx={{
                  mt: "10px",
                  "& .MuiPaper-root": {
                    backgroundColor: "#1E1E2F", // Modern dark background
                    color: "#fff", // Text color
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.5)", // Modern shadow
                  },
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: "text.secondary",
                    }}
                  >
                    Hi, {employeeCode}
                  </Typography>
                  <StyledButtonL
                    variant="contained"
                    size="small"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <LogoutIcon sx={{ marginRight: 0.5 }} /> Logout
                  </StyledButtonL>
                </Box>
              </Menu>

              <StyledButtonLc
                size="small"
                disableRipple
                color="inherit"
                // sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </StyledButtonLc>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
