import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  DialogContentText,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import useApprovalEntriesApi from "../../../../customApiHooks/approvalApi";
import {
  ViewButton,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import {
  dialogStyles,
  typoStyle,
  mdBoxStyle,
  dialogContentStyle,
  containerStyle,
  tableCellStyle,
  tableCellFontStyle,
  subDialogContentStyle,
  dialogContentTextStyle,
  approvalStyles,
} from "components/Styles/approvalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import { getBackgroundColor } from "components/StyledComponents/styledComponents";

export default function LoanDisbursement() {
  const [openPopup, setOpenPopup] = useState(false);
  const [opexPercent, setOpexPercent] = useState(0.1);
  const [grossPayoutAmount, setGrossPayoutAmount] = useState(0); // State for Gross Payout Amount
  const [dsapayoutAmount, setDsapayoutAmount] = useState(0); // State for DSA Payout Amount
  const [subDsaPayoutAmount, setSubDsaPayoutAmount] = useState(0);
  const [opexAmount, setOpexAmount] = useState(0); // State for Operation Charges (Opex)
  const [managerBusinessCredit, setManagerBusinessCredit] = useState(0);
  const [typeCode, setTypeCode] = useState("BL");
  const [entryStatus, setEntryStatus] = useState("N");
  const [balance, setBalance] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const MInput = inputStyle();
  const [selectedData, setSelectedData] = React.useState({
    disbursedAmount: 0,
  });
  const {
    nonApprovalEntryData,
    approveEntryApiCall,
    open,
    message,
    loading,
    setLoading,
    handleCloseAlert,
    fetchEntrieById,
    businessEntry,
    showAlert,
    alertType,
    isEditMode,
    setIsEditMode,
  } = useApprovalEntriesApi(typeCode, entryStatus);

  const handleDisbursedAmountChange = (event) => {
    const value = event.target.value;
    // Allow empty input to clear the field
    if (value === "") {
      setSelectedData({
        ...selectedData,
        disbursedAmount: "",
      });
      return;
    }
    // Regular expression to allow only numbers with up to two decimal places
    const numericValue = value.replace(/[^0-9.]/g, "");
    if (numericValue) {
      setSelectedData({
        ...selectedData,
        disbursedAmount: numericValue,
      });
    }
  };
  const formattedAmount = selectedData.disbursedAmount;
  // selectedData.disbursedAmount !== ""
  //   ? Number(selectedData.disbursedAmount).toLocaleString("en-IN")
  //   : "";
  const handleViewClick = async (rowData) => {
    if (rowData.entryId) {
      await fetchEntrieById(rowData.entryId); // Directly use rowData.entryId
    }
    setSelectedData(rowData);
    setOpenPopup(true);
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format it as "YYYY-MM-DD"
  }
  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    const rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    return (
      <ViewButton type="button" onClick={() => handleViewClick(rowData)}>
        <VisibilityIcon />
      </ViewButton>
    );
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 120,
      flex: 1,
      filter: true,
      sorted: true,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Loan Amount",
      field: "appliedAmount",
      filter: "agNumberColumnFilter", // Ensure filter is enabled for numbers
      sortable: true,
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`, // Formats and rounds to two decimals
      headerClass: "header-center",
    },
    {
      headerName: "DSA Name",
      field: "mainDSA",
      filter: true,
      sortable: true,
      minWidth: 120,
      flex: 1.5,
    },
    {
      headerName: "Banker Name",
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "View",
      cellRenderer: ActionCellRenderer,
      width: 80,
    },
  ];

  const calculatePayoutAmounts = (disbursedAmount, grossPayout, dsapayout, subdsaPayout) => {
    const grossPayoutAmount = ((disbursedAmount * grossPayout) / 100).toFixed(2);
    const dsapayoutAmount = ((grossPayoutAmount * dsapayout) / 100).toFixed(2);
    const subDsaPayoutAmount = ((dsapayoutAmount * subdsaPayout) / 100).toFixed(2);
    return { grossPayoutAmount, dsapayoutAmount, subDsaPayoutAmount };
  };

  useEffect(() => {
    if (selectedData) {
      const { grossPayoutAmount, dsapayoutAmount, subDsaPayoutAmount } = calculatePayoutAmounts(
        selectedData.disbursedAmount,
        selectedData.grossPayoutPercent,
        selectedData.dsaPayoutPercent,
        selectedData.subDsaPayoutPercent
      );
      setGrossPayoutAmount(grossPayoutAmount);
      setDsapayoutAmount(dsapayoutAmount);
      setSubDsaPayoutAmount(subDsaPayoutAmount);
      // Calculate balance
      const balance = grossPayoutAmount - dsapayoutAmount;
      setBalance(balance.toFixed(2));
      // Calculate Opex and Manager Business Credit
      const calculatedOpexAmount = (balance * opexPercent).toFixed(2); // 10% for Opex
      const calculatedManagerBusinessCredit = (balance * 0.9).toFixed(2); // 90% for Manager Business Credit
      // Set Opex and Manager Business Credit amounts
      setOpexAmount(calculatedOpexAmount);
      setManagerBusinessCredit(calculatedManagerBusinessCredit);
    }
  }, [selectedData, opexPercent]); // Add opexPercent as a dependency

  const handleClose = () => {
    setOpenPopup(false);
  };
  // Function to open the dialog
  const handleOpenDialog = () => {
    // Check if the disbursed amount is zero
    if (selectedData && selectedData.disbursedAmount == 0) {
      showAlert("Enter Disbursed amount", "error"); // Show alert with an error message
      return; // Exit the function to prevent the API call
    }
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle "Yes" confirmation
  const handleConfirmClick = async () => {
    const data = {
      ...businessEntry,
      businessStatus: "D".charAt(0),
      disbursedAmount: selectedData.disbursedAmount,
      grossPayoutAmount: grossPayoutAmount,
      dsaPayoutAmount: dsapayoutAmount,
      subDsaPaymentAmount: subDsaPayoutAmount,
    };
    await approveEntryApiCall(selectedData.entryId, data);
    setOpenDialog(false);
    setOpenPopup(false);
    setSelectedData({
      disbursedAmount: 0,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={nonApprovalEntryData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={15}
          />
        </StyledAgGridContainer>
      </TableContainer>
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          ...dialogStyles,
        }}
      >
        <DialogTitle sx={{ position: "relative", padding: "0px", zIndex: "1000" }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
            sx={{ ...mdBoxStyle, backgroundColor: MInput.a }}
          >
            <Typography variant="h6" fontWeight="medium" color="#FFFFFF" {...typoStyle}>
              Loan Entry Details
            </Typography>
          </MDBox>
        </DialogTitle>
        <DialogContent {...dialogContentStyle}>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Loan Id"
                fullWidth
                value={selectedData.entryId}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label="Date"
                fullWidth
                value={
                  selectedData?.date
                    ? formatTimestampToDate(selectedData.date)
                    : new Date().toLocaleDateString()
                }
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Application Number"
                fullWidth
                value={selectedData.applicatioNumber}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="DSA Name"
                fullWidth
                value={selectedData.mainDSA || "Unknown DSA"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Sub DSA"
                fullWidth
                value={selectedData.subDSA || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Andromeda Manager"
                fullWidth
                value={selectedData.salesManager || "Unknown Manager"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Dsa Sales Manager"
                fullWidth
                value={selectedData.dsaSalesManager || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Policy Name"
                fullWidth
                value={selectedData.businessName || "Unknown Loan"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Name"
                fullWidth
                value={selectedData.customerName}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Banker Name"
                fullWidth
                value={selectedData.financialInstitutionName || "Unknown Bank"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Loan Amount"
                fullWidth
                value={`${selectedData?.appliedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Loan Disbursed Amount"
                fullWidth
                value={formattedAmount || ""}
                onChange={handleDisbursedAmountChange}
                InputLabelProps={{
                  className:
                    selectedData.disbursedAmount || selectedData.disbursedAmount === 0
                      ? "inputLabelShrink"
                      : "inputLabel",
                  sx: {
                    color: MInput.a,
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
                error={selectedData.disbursedAmount === ""}
                helperText={selectedData.disbursedAmount === "" ? "This field is required" : ""}
                sx={MInput.b}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label="Remarks"
                multiline
                rows={1}
                fullWidth
                value={selectedData.remarks}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} {...containerStyle}>
            <Table>
              <TableBody>
                <TableRow {...tableCellStyle}>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="bold"
                  ></CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    Payout %
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    Payout Amount
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    Banker
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold" align="right">
                    {Number(selectedData.grossPayoutPercent).toFixed(2)}
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(grossPayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    DSA
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} align="right" fontWeight="bold">
                    {Number(selectedData.dsaPayoutPercent).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(dsapayoutAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    Balance
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="bold"
                  ></CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(balance) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    Less : OPEX
                  </CustomTableCell>
                  <CustomTableCell align="right" minWidth="100px" flexGrow={1} fontWeight="bold">
                    {(opexPercent * 100).toFixed(2)}
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(opexAmount) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    Manager Business Credit
                  </CustomTableCell>
                  <CustomTableCell
                    minWidth="100px"
                    flexGrow={1}
                    fontWeight="bold"
                  ></CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold" align="right">
                    ₹
                    {` ${(Number(managerBusinessCredit) || 0).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to close this business with ${selectedData.disbursedAmount} disbursed Amount?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={approvalStyles.dialogActions}>
            <StyledButton onClick={handleConfirmClick}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDialog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <DialogActions>
          <StyledButton onClick={handleOpenDialog}>Confirm</StyledButton>
          <StyledButton onClick={handleClose}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
LoanDisbursement.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
