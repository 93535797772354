import { selectScrollStyleD } from "./globalStyles";
export const voucherMasterStyles = {
  tableContainerShadowStyle: {
    width: "96%",
    maxWidth: "820px",
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2)",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px", // Optional: rounded corners
    padding: "0px", // Optional: padding inside the container
    margin: "0px auto",
    maxHeight: "210px",
    ...selectScrollStyleD,
  },
  tableHead: {
    position: "sticky",
    top: 0,
    alignItems: "stretch",
    backgroundColor: "#ffffff", // Ensure the header stays visible
    zIndex: 10,
  },
  tableCellStyle: {
    fontSize: "14px",
    fontWeight: "bold",
    width: {
      xs: "120px",
      md: "250px",
    },
  },

  formPadding: {
    padding: "20px",
  },
  selectClass: {
    height: "40px",
    width: "100%",
  },
  amountFeild: {
    width: "180px",
  },
  autoSelectClass: {
    backgroundColor: "#ffffff",
    zIndex: 1,
  },
  tableContainertwoStyle: {
    display: "flex",
    flexDirection: "column", // Allow children to stack vertically
    justifyContent: "flex-start",
    alignItems: "stretch", // Stretch children to full width
    padding: "10px",
    height: "100%", // Full height for parent
    width: "100%", // Full width for parent
  },
  agGridStyle: {
    flexGrow: 1, // Allow AgGrid to grow dynamically
    marginTop: "10px",
    width: "100%", // Ensure it spans full width
  },
  mdBoxStyle: {
    display: "flex",
    flexWrap: "wrap", // Allow wrapping on smaller screens
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },

  subMdBoxStyle: {
    display: "flex",
    flexWrap: "wrap", // Enable wrapping within this box if needed
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "8px",
    width: "100%",
  },
  textFieldClass: {
    flex: "1 1 110px", // Responsive width
    maxWidth: "140px", // Set max width constraint
    minWidth: "120px", // Set min width to control wrapping
  },
  buttonFieldClass: {
    flex: "1 1 100px", // Responsive width
    maxWidth: "80px",
    minWidth: "60px",
  },
  agGridStyle: {
    height: "87%",
    marginTop: "10px",
    minWidth: "100%",
  },
  paymentVoucher: {
    ...selectScrollStyleD,
    minHeight: "200px", // Set minimum height to 300px
    overFlow: "scroll",
    paddingBottom: "24px",
  },
};
