import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types"; // For prop-types validation
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  TableContainer,
  Paper,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { writeFile, utils } from "xlsx"; // Import XLSX for Excel export
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"; // Download icon for the button
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { CustomPaper } from "components/Styles/globalStyles";
import URLs from "constants/urls";
export default function MonthlyBusiness() {
  const { monthIdData, setAlert, handleClose } = useBusinessMonthYear();
  //   const [monthId, setMonthId] = useState(monthIdData[0].businessMonthID);
  const [monthId, setMonthId] = useState(0);
  const businessCodeRef = useRef(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const MInput = inputStyle();

  const fetchEntriesByMonthId = async () => {
    setLoading(true);
    try {
      // Fetch entries by monthId
      const response = await fetch(`${URLs.monthlyBusinessUrl}/${monthId}`);
      const data = await response.json();
      if (response.ok) {
        setServicesData(data);
      } else {
        throw new Error("Failed to fetch entries data");
      }
    } catch (error) {
      showAlert(`Failed to fetch data: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  const showAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (monthIdData && monthIdData.length > 0) {
      // Set monthId only when monthIdData exists and is not empty
      setMonthId(monthIdData[0].businessMonthID);
      fetchEntriesByMonthId();
    }
  }, [monthIdData]); // Dependency on monthIdData

  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      if (!servicesData.length) return;
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(servicesData); // Convert the entire rowData to a sheet
      utils.book_append_sheet(wb, ws, "EmployeeData");
      writeFile(wb, "Montlhy_Business_entries_Data.xlsx"); // Save the workbook
    };

    return (
      <StyledButton onClick={handleDownload}>
        <DownloadForOfflineIcon fontSize="medium" />
      </StyledButton>
    );
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
  }
  const columns = [
    {
      headerName: "Business Date",
      field: "businessDate",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Current Status",
      field: "currentStatus",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Appl. No.",
      field: "applicationNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Business Name",
      field: "businessName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Provider Name",
      field: "finainacialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
    {
      headerName: "Customer Name",
      field: "customerName",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "Main DSA",
      field: "mainDsa",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Sales Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "DSA Sales Manager",
      field: "dsaSalesmanager",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      valueGetter: (params) => (params.data.dsaSalesmanager ? params.data.dsaSalesmanager : "N/A"),
    },
    {
      headerName: "Sub DSA",
      field: "subDsa",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => (params.data.subDsa ? params.data.subDsa : "N/A"),
    },
    {
      headerName: "Applied Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Disbursed Amount",
      field: "disbursedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Gross Payout (%)",
      field: "grossPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 180,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Gross Payout Amount",
      field: "grossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 230,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final Gross Payout Amount",
      field: "finalGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "DSA Payout (%)",
      field: "dsapayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "DSA Payout Amount",
      field: "dsapayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final DSA Gross Payout Amount",
      field: "finalDsaGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Sub DSA Payout (%)",
      field: "subDsaPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Sub DSA Payment Amount",
      field: "subDsaPaymentAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${
          params.value
            ? params.value.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0"
        }`,
    },
    {
      headerName: "Margin Expected",
      field: "marginExepected",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value; // Get the value
        return value != null // Check if the value is not null or undefined
          ? `₹ ${value.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "₹ 0"; // Fallback value if null
      },
    },
    {
      headerName: "Margin Received",
      field: "marginReceived",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value; // Get the value
        return value != null // Check if the value is not null or undefined
          ? `₹ ${value.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "₹ 0"; // Fallback value if null
      },
    },

    {
      headerName: "Remarks",
      field: "remarks",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
  ];

  const triggerApiCall = () => {
    businessCodeRef.current = monthId;
    fetchEntriesByMonthId();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date} // Display 'date' field from monthIdData
              value={monthIdData.find((item) => item.businessMonthID === monthId) || null} // Set the value based on monthId
              onChange={(event, newValue) => {
                if (newValue) {
                  setMonthId(newValue.businessMonthID);
                }
              }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.date.toLowerCase().startsWith(inputValue)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.date.toLowerCase().startsWith(inputValue) &&
                    option.date.toLowerCase().includes(inputValue)
                );

                // Combine the options with priority for startsWithInput
                return [...startsWithInput, ...containsInput];
              }}
              disableClearable={false}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "160px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
            />
            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>
          {ActionCellRenderer()}
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={servicesData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
