import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
export const selectScrollStyle = {
  maxHeight: "200px",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "8px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Color of the scrollbar thumb
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#888", // Color of the scrollbar thumb on hover
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Color of the scrollbar track
  },
};
export const selectScrollStyleD = {
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "8px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Color of the scrollbar thumb
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#888", // Color of the scrollbar thumb on hover
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Color of the scrollbar track
  },
};
export const tableShadow = {
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
};
import { makeStyles } from "@mui/styles";

const useGlobalStyles = makeStyles({
  autoSelectClass: {
    height: "11px",
  },
  approvalTable: {
    display: "flex",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    // maxHeight: "700px", // Maximum height (you can adjust this)
    marginTop: "16px",
  },
  accountsTable: {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    maxHeight: "800px", // Maximum height (you can adjust this)
    padding: "16px",
    marginTop: "16px",
  },
  mainTable: {
    display: "flex",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    maxHeight: "800px", // Maximum height (you can adjust this)
    padding: "16px",
    marginTop: "16px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "500px",
    marginBottom: "16px",
    marginTop: "8px",
    gap: "8px",
  },
  accountsCard: {
    display: "flex",
    flexDirection: "row",
    gap: "8px", // Single gap property
    width: "50vw",
    maxWidth: "500px",
    minWidth: "250px",
  },
  HorizontalscrollStyle: {
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "8px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "purple",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "white",
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  mdBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "250px",
    width: "100%",
    overflow: "scroll",
    backgroundColor: "purple",
    color: "white",
    padding: "24px",
    margin: "16px",
  },
});
export const globalStyles = {
  autoSelectClass: {
    height: "11px",
  },
  approvalTable: {
    display: "flex",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    maxHeight: "700px", // Maximum height (you can adjust this)
    marginTop: "16px",
  },
  accountsTable: {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    maxHeight: "800px", // Maximum height (you can adjust this)
    padding: "16px",
    marginTop: "16px",
  },
  mainTable: {
    display: "flex",
    width: "100%", // Full width
    height: "calc(100vh - 16.5vh)", // Full height minus the header height (10vh for example)
    minHeight: "200px",
    maxHeight: "800px", // Maximum height (you can adjust this)
    padding: "16px",
    marginTop: "16px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "400px",
    marginBottom: "16px",
    marginTop: "8px",
    gap: "8px",
  },
  HorizontalscrollStyle: {
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "8px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "purple",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "white",
      boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  mdBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "250px",
    width: "100%",
    overflow: "scroll",
    backgroundColor: "purple",
    color: "white",
    padding: "24px",
    margin: "16px",
  },
};
export default useGlobalStyles;
export const tableCellStyle = {
  fontSize: "14px",
  flexGrow: 1,
  fontWeight: "bold",
  textAlign: "center",
};
export const tableCellStyle2 = {
  fontSize: "14px",
  fontWeight: "bold",
  marginRight: "350px",
};
export const autoSelectStyle = {
  height: 43,
  "& .MuiInputBase-root": {
    minHeight: 43,
  },
};
export const autoSelectStyle2 = {
  width: "20vw",
  minWidth: "120px",
  maxWidth: "200px",
  height: 43,
  "& .MuiInputBase-root": {
    minHeight: 43,
  },
};
export function CustomPaper({ children }) {
  return (
    <Paper
      sx={{
        "& .MuiAutocomplete-listbox": {
          maxHeight: 200, // Limit height for scrolling
          overflowY: "auto", // Enable vertical scroll
          // Custom scroll bar styles
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#4A8974", // Scrollbar thumb color
            borderRadius: "4px", // Round edges of scrollbar thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", // Thumb color on hover
          },
          "& .MuiAutocomplete-option[aria-selected='true']": {
            bgcolor: "purple", // Background color for selected option
            "&.Mui-focused": {
              bgcolor: "purple", // Background for selected + focused
            },
          },
          "& .MuiAutocomplete-option.Mui-focused:not([aria-selected='true'])": {
            bgcolor: "#2E6E9E", // Background for focused but not selected
            color: "#ffffff",
          },
        },
      }}
    >
      {children}
    </Paper>
  );
}
CustomPaper.propTypes = {
  children: PropTypes.node.isRequired,
};
