export const ledgerStyles = {
  formItem: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  selectField: {
    height: "40px",
    width: "80px",
  },
  mdBoxStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  yearField: {
    height: "40px",
    width: "80px",
  },
  girdClass: {
    height: "60vh",
    minHeight: "350px",
    maxHeight: "600px",
    marginTop: "20px",
    minWidth: "100%", // Corrected from minwidth to minWidth
  },
  menuProps: {
    maxHeight: 200,
    overflow: "auto",
  },
  selectClass: {
    height: "40px", // Set height of the select box
    "& .MuiSelect-select": {
      height: "40px", // Ensure content fits the height
      display: "flex",
      alignItems: "center",
    },
    fontSize: {
      xs: "0.75rem",
      sm: "0.8rem",
      md: "0.8rem",
      fontWeight: "400",
    },
    overflow: "hidden",
  },
  snackbar: {
    marginTop: "48px",
  },
  tableContainer: {
    width: "100%",
    padding: "20px",
  },
  formContainer: {
    marginTop: "16px",
    marginLeft: "8px",
    marginBottom: "16px",
    display: "flex",
    width: "98%",
    minWidth: "500px",
    paddingTop: "12px",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: "16px",
  },
  formItem: {
    marginTop: "16px",
    marginLeft: "16px",
    marginBottom: "16px",
    display: "flex",
    width: "45%",
    minWidth: "300px",
    paddingTop: "4px",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  },
  selectField: {
    height: "40px",
    width: "100px",
  },
  yearField: {
    height: "40px",
    width: "100px",
    color: "#000000",
  },
  table: {
    minWidth: 400,
    width: "100%",
    margin: "auto",
    boxShadow: "0px 4px 20px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
  },
  tableRow: {
    borderBottom: "1px solid #ccc",
  },
  mainTableRow: {
    borderBottom: "2px solid #ccc",
  },
  tableCell: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  buttonContainer: {
    display: "flex",
    marginRight: "80px",
    justifyContent: "flex-end", // Aligns items to the right
    marginTop: "16px",
  },
  label: {
    backgroundColor: "#ffffff",
    width: "150px",
  },
};
