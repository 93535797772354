import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableContainer, Paper, Grid, TextField, Tooltip } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
} from "@mui/material";
import useStylesGroup from "components/Styles/groupcreation";
import {
  selectScrollStyle,
  tableCellStyle,
  CustomPaper,
  tableShadow,
  autoSelectStyle,
  autoSelectStyle2,
} from "components/Styles/globalStyles";
import { globalStyles } from "components/Styles/globalStyles";
import { groupStyles } from "components/Styles/groupcreation";
import URLs from "constants/urls";

export default function GroupCreation() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [parentGroups, setParentGroups] = useState([]);
  const [selectedSubGroupName, setSelectedSubGroupName] = useState("");
  const [selectedMainGroup, setSelectedMainGroup] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const MInput = inputStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch main groups for the dropdown
  useEffect(() => {
    fetchParentGroups();
    fetchAllGroups();
  }, []);

  async function fetchParentGroups() {
    setLoading(true);
    try {
      const response = await fetch(URLs.ParentGroupUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch parent groups");
      const data = await response.json();
      setParentGroups(data);
    } catch (error) {
      setAlert("Error fetching parent groups: " + error.message, "error");
    }
    setLoading(false);
  }

  // Fetch all groups to display in the table
  async function fetchAllGroups() {
    setLoading(true);
    try {
      const response = await fetch(URLs.AllGroupUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Failed to fetch groups");
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      setAlert("Error fetching groups: " + error.message, "error");
    }
    setLoading(false);
  }

  const handleSaveGroup = async (event) => {
    event.preventDefault();
    const requestData = {
      RequestType: isEditMode ? "UPDATE" : "ADD",
      groupId: isEditMode ? selectedId : "0", // 0 for new entries
      parentGroupId: parseInt(selectedMainGroup),
      groupName: selectedSubGroupName,
      CreateUserID: "LoginUser",
      ModifiedUserID: "LoginUser",
    };
    setLoading(true);
    try {
      const response = await fetch(URLs.PostAccountGroupUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      if (!response.ok)
        throw new Error(isEditMode ? "Failed to update group" : "Failed to add group");
      setAlert(isEditMode ? "Group updated successfully" : "Group added successfully", "success");
      fetchAllGroups(); // Refresh group data
      resetInputData();
    } catch (error) {
      setAlert("Error saving group: " + error.message, "error");
    }
    setLoading(false);
  };

  const handleDeleteGroup = (id, name) => {
    setDeleteGroupId(id); // Set the ID of the group to be deleted
    setGroupName(name); // Set the name of the group for dialog display
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const requestData = {
        RequestType: "DELETE",
        groupId: deleteGroupId, // Use the ID set in the state
        CreateUserID: "LoginUser",
        ModifiedUserID: "LoginUser",
      };

      const response = await fetch(URLs.PostAccountGroupUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) throw new Error("Failed to delete group");

      setAlert("Group deleted successfully", "success");
      fetchAllGroups(); // Refresh group data
    } catch (error) {
      setAlert("Error deleting group: " + error.message, "error");
    }
    setOpenDialog(false); // Close the dialog
    setLoading(false);
  };

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleViewClick = (rowData) => {
    setSelectedId(rowData.groupId);
    setSelectedSubGroupName(rowData.groupName || "");
    setSelectedMainGroup(rowData.parentGroupId || "");
    setIsEditMode(true);
  };

  const resetInputData = () => {
    setSelectedSubGroupName("");
    setSelectedMainGroup("");
    setSelectedId(null);
    setIsEditMode(false);
  };

  const handleCancelDelete = () => {
    setDeleteGroupId(null);
    setOpenDialog(false);
  };

  const ActionCellRenderer = (props) => {
    const rowData = props.data;

    return (
      <div>
        <ViewButton onClick={() => handleViewClick(rowData)}>
          <EditIcon />
        </ViewButton>
        {/* Open the confirmation dialog instead of deleting directly */}
        <DeleteButton onClick={() => handleDeleteGroup(rowData.groupId, rowData.groupName)}>
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };

  ActionCellRenderer.propTypes = {
    data: PropTypes.shape({
      groupId: PropTypes.number.isRequired,
      groupName: PropTypes.string,
      accountName: PropTypes.string,
      parentGroupId: PropTypes.string,
    }).isRequired,
  };

  const columnDefs = [
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer,
      minWidth: 120,
      flex: 1,
    },
    { headerName: "Group Name", field: "groupName", sortable: true, filter: true, flex: 2 },
    { headerName: "Main Group", field: "parentGroupName", sortable: true, filter: true, flex: 1 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Grid container spacing={2}>
          <Dialog open={openDialog}>
            <DialogContent>
              <DialogContentText>
                {`Are you sure you want to delete the group "${groupName}"?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
              <StyledButton onClick={handleCancelDelete} variant="outlined">
                No
              </StyledButton>
            </DialogActions>
          </Dialog>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow }}>
              <div style={{ position: "relative", width: "100%" }}>
                <StyledTypography>{!isEditMode ? "Group Creation" : "Edit Group"}</StyledTypography>
                {isEditMode && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetInputData}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <Grid sx={groupStyles.root}>
                <StyledSnackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <StyledAlert onClose={() => setOpen(false)} severity={alertType}>
                    {message}
                  </StyledAlert>
                </StyledSnackbar>
                <form onSubmit={handleSaveGroup} autoComplete="off">
                  <Grid container spacing={2} pt={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Sub Group Name"
                        fullWidth
                        required
                        value={selectedSubGroupName}
                        onChange={(e) => setSelectedSubGroupName(e.target.value)}
                        inputProps={{ maxLength: 120 }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={parentGroups}
                        getOptionLabel={(option) => option.groupName || ""}
                        value={
                          selectedMainGroup
                            ? parentGroups.find((group) => group.groupId === selectedMainGroup)
                            : null
                        }
                        onChange={(event, newValue) => {
                          setSelectedMainGroup(newValue ? newValue.groupId : ""); // Update selectedMainGroup
                        }}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Filter options that start with the input value
                          const startsWithInput = options.filter((option) =>
                            option.groupName.toLowerCase().startsWith(input)
                          );

                          // Filter options that contain the input value but do not start with it
                          const containsInput = options.filter(
                            (option) =>
                              !option.groupName.toLowerCase().startsWith(input) &&
                              option.groupName.toLowerCase().includes(input)
                          );

                          // Combine the options: ones that start with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper} // Optional custom styling
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Main Group"
                            fullWidth
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              ...groupStyles.labelClass,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Default label color
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={groupStyles.buttonGrid}>
                    <StyledButton type="submit">{isEditMode ? "Update" : "ADD"}</StyledButton>
                  </Grid>
                </form>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
              <AgGridReact
                rowData={tableData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={12}
              />
            </StyledAgGridContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}
