import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import URLs from "constants/urls";
import {
  Box,
  Table,
  TableBody,
  TextField,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  CustomTableCell,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { CustomPaper, autoSelectStyle, globalStyles } from "components/Styles/globalStyles";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";

export default function MonthEnd() {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [businessMonthID, setBusinessMonthID] = useState(0);
  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const MInput = inputStyle();

  const [monthEndDetails, setMonthEndDetails] = useState({
    businessMonthID: 0,
    monthEndDate: 0,
    grossPayoutOnLoan: 0,
    grossPayoutOnInsurance: 0,
    grossPayoutOnMutualFund: 0,
    loyalty: 0,
    netProfit: 0,
    username: "",
    expenses: 0,
    dsapayoutAmountOnMutualFund: 0,
    dsapayoutAmountOnInsurance: 0,
    dsapayoutAmountOnLoan: 0,
  });

  const handleCloseDailog = () => {
    setOpenDialog(false); // Close the dialog when 'No' is clicked
  };

  const { monthIdData, loadingBId, message, alertType, setAlert, open, handleClose, fetchData } =
    useBusinessMonthYear();
  const [totalGrossPayout, setTotalGrossPayout] = useState(0);
  const [totalDsaPayouts, setTotalDsaPayouts] = useState(0);
  const [netProfit, setNetProfit] = useState(0);

  const getMonthEndDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${URLs.monthEndUrl}/${businessMonthID}`);
      if (response.ok) {
        const data = await response.json();
        setMonthEndDetails(data.data);
        setBusinessMonthID(data.data.businessMonthID || data.businessMonthID);
      } else {
        // Handle the case when the response is not OK
        const errorData = await response.json();
        setAlert(errorData.message || "Failed to fetch data", "error");
      }
    } catch (error) {
      setAlert("An error occurred while fetching data", "error");
    }
    setLoading(false);
  };

  const saveMonthEndDetails = async () => {
    const url = URLs.monthEndUrl;
    const monthEndPayload = {
      businessMonthID: monthEndDetails.businessMonthID,
      businessDate: formattedDate,
      grossPayoutOnLoan: monthEndDetails.grossPayoutOnLoan || 0,
      dsapayoutAmountOnLoan: monthEndDetails.dsapayoutAmountOnLoan,
      grossPayoutOnInsurance: monthEndDetails.grossPayoutOnInsurance,
      dsapayoutAmountOnInsurance: monthEndDetails.dsapayoutAmountOnInsurance,
      grossPayoutOnMutualFund: monthEndDetails.grossPayoutOnMutualFund,
      dsapayoutAmountOnMutualFund: monthEndDetails.dsapayoutAmountOnMutualFund,
      expenditure: monthEndDetails.expenditure,
      netProfit: monthEndDetails.netProfit,
      loyalty: monthEndDetails.loyalty,
      username: "admin",
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(monthEndPayload), // Send monthEndDetails as the payload
    };
    setLoading(true);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        setAlert("Data saved successfully", "success"); // Show success alert
        await fetchData();
      } else {
        const errorData = await response.json();
        setAlert(errorData.message || "Failed to save data", "error"); // Show error alert with specific message
      }
    } catch (error) {
      setAlert("An error occurred while saving data", "error"); // Show error alert on exception
    }
    setLoading(false);
    setOpenDialog(false);
  };
  const handleGetClick = () => {
    if (businessMonthID) {
      getMonthEndDetails();
    }
  };

  const calculateTotals = () => {
    const totalGross =
      monthEndDetails.grossPayoutOnLoan +
      monthEndDetails.grossPayoutOnInsurance +
      monthEndDetails.grossPayoutOnMutualFund;

    const totalDsa =
      monthEndDetails.dsapayoutAmountOnLoan +
      monthEndDetails.dsapayoutAmountOnMutualFund +
      monthEndDetails.dsapayoutAmountOnInsurance;

    const profit = totalGross - totalDsa;

    setTotalGrossPayout(totalGross);
    setTotalDsaPayouts(totalDsa);
    setNetProfit(profit);
  };

  useEffect(() => {
    getMonthEndDetails();
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [monthEndDetails]);

  const handleSave = () => {
    saveMonthEndDetails();
  };
  const handleConfirmSave = () => {
    handleSave();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer1}>
        <Box sx={monthEndStyles.formContainer1}>
          <Autocomplete
            options={monthIdData}
            getOptionLabel={(option) => option.date || ""}
            value={
              businessMonthID
                ? monthIdData.find((item) => item.businessMonthID === businessMonthID)
                : null
            }
            onChange={(event, newValue) => {
              setBusinessMonthID(newValue ? newValue.businessMonthID : "");
            }}
            PaperComponent={CustomPaper} // Add your custom Paper component here if needed
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label="Date"
                variant="outlined"
                required
                sx={{
                  height: 43,
                  width: "160px",
                  ...MInput.b,
                  "& .MuiInputBase-root": {
                    height: "100%",
                    minHeight: 43,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: MInput.a, // Ensure this applies to the label
                    "&.Mui-focused": {
                      color: "red", // Focused label color
                    },
                  },
                }}
              />
            )}
          />
          <StyledButton onClick={handleGetClick}>Get</StyledButton>
        </Box>
        <Table sx={monthEndStyles.table}>
          <TableBody>
            <TableRow sx={monthEndStyles.mainTableRow}>
              <CustomTableCell minWidth="100px" flexGrow={2} fontWeight="bold">
                <strong>Business</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1} fontWeight="bold">
                <strong>Bank to Andromeda</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1} fontWeight="bold">
                <strong>Andromeda to DSA</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1} fontWeight="bold">
                <strong>Gross Profit</strong>
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Loans</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.grossPayoutOnLoan).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.dsapayoutAmountOnLoan).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(
                  monthEndDetails.grossPayoutOnLoan - monthEndDetails.dsapayoutAmountOnLoan
                ).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Insurance</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.grossPayoutOnInsurance).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.dsapayoutAmountOnInsurance).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(
                  monthEndDetails.grossPayoutOnInsurance -
                    monthEndDetails.dsapayoutAmountOnInsurance
                ).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Mutual Funds</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.grossPayoutOnMutualFund).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.dsapayoutAmountOnMutualFund).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(
                  monthEndDetails.grossPayoutOnMutualFund -
                    monthEndDetails.dsapayoutAmountOnMutualFund
                ).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1}>
                <strong>Total</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(totalGrossPayout).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(totalDsaPayouts).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(netProfit).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={3}>
                <strong>Less : Monthly Expenditure</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.expenses).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={3}>
                <strong>Net Profit</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.netProfit).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
            <TableRow sx={monthEndStyles.tableRow}>
              <CustomTableCell minWidth="100px" flexGrow={1} colSpan={3}>
                <strong>Loyalty to DMA</strong>
              </CustomTableCell>
              <CustomTableCell align="right" minWidth="100px" flexGrow={1}>
                {`₹ ${Math.round(monthEndDetails.loyalty).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Dialog open={openDialog} onClose={handleCloseDailog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              This action will finalize all business transactions for the month. Are you sure you
              want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={monthEndStyles.dialogActions}>
            <StyledButton onClick={handleConfirmSave}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDailog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <div style={monthEndStyles.buttonContainer}>
          <StyledButton onClick={() => setOpenDialog(true)}>Save</StyledButton>
        </div>
      </TableContainer>
    </DashboardLayout>
  );
}
