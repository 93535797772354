import React from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTimeline } from "examples/Timeline/context";
import timelineItem from "examples/Timeline/TimelineItem/styles";

function TimelineItem({
  color,
  icon,
  title,
  totalApplications,
  rejected,
  revenue,
  description,
  lastItem,
  monthlyOverviewD,
}) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="380px">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 3, // Adds a medium box shadow
              borderRadius: "4px", // Optional: Adds border radius
              padding: "8px", // Reduced padding around the table
              width: "95%", // Restricts width of the container
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{ fontSize: "0.9rem", fontWeight: "bold", padding: "4px" }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "0.9rem", fontWeight: "bold", padding: "4px" }}
                    align="right"
                  >
                    Accepted
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "0.9rem", fontWeight: "bold", padding: "4px" }}
                    align="right"
                  >
                    Revenue
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      padding: "4px",
                    }}
                  >
                    {parseInt(monthlyOverviewD.totalApplications).toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      padding: "4px",
                    }}
                  >
                    {parseInt(monthlyOverviewD.disbursedApplications).toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: "500",
                      fontSize: "0.9rem",
                      padding: "4px",
                    }}
                  >
                    {`₹\u00A0${parseFloat(monthlyOverviewD.revenue).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description && (
            <MDTypography variant="button" color={isDark ? "white" : "dark"}>
              {description}
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  totalApplications: PropTypes.number.isRequired,
  rejected: PropTypes.number.isRequired,
  revenue: PropTypes.number.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
  monthlyOverviewD: PropTypes.isRequired,
};

export default TimelineItem;
