import { autoSelectStyle } from "./globalStyles";

export const entryStyles = {
  root: {
    height: "100%",
    minHeight: "500px",
    maxHeight: "640px",
    marginTop: "10px",
    overflowY: "auto",
    padding: "15px",
  },
  labelClass: {
    backgroundColor: "#FFFFFF",
  },
  tableContainertwoStyle: {
    display: "flex",
    flexDirection: "column", // Allow children to stack vertically
    justifyContent: "flex-start",
    alignItems: "stretch", // Stretch children to full width
    padding: "10px",
    height: "100%", // Full height for parent
    width: "100%", // Full width for parent
  },
  mdBoxStyle: {
    display: "flex",
    flexWrap: "wrap", // Allow wrapping on smaller screens
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
  autoEntrySelectStyle: {
    width: "20vw",
    minWidth: "150px",
    maxWidth: "250px",
  },
  subMdBoxStyle: {
    display: "flex",
    flexWrap: "wrap", // Enable wrapping within this box if needed
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "8px",
    width: "100%",
  },
  tableContainer: {
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2), 0 4px 8px -2px rgba(0,0,0,0.2)", // Top and bottom shadow
    borderRadius: "8px", // Optional: rounded corners
    padding: "0px", // Optional: padding inside the container
    marginTop: "15px",
  },
  girdClass: {
    height: "98%",
    minHeight: "420px",
    maxHeight: "800px",
    marginTop: "20px",
    minwidth: "100%",
  },
  selectClass: {
    height: "40px", // Set height of the select box
    "& .MuiSelect-select": {
      height: "40px", // Ensure content fits the height
      display: "flex",
      alignItems: "center",
    },
    fontSize: {
      xs: "0.75rem",
      sm: "0.8rem",
      md: "0.8rem",
      fontWeight: "400",
    },
    overflow: "hidden",
  },
  marginStyle: {
    marginTop: "0px",
  },
  divStyle: {
    position: "relative",
    width: "100%",
  },
  tableServicesStyle: {
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2), 0 4px 8px -2px rgba(0,0,0,0.2)", // Top and bottom shadow
    borderRadius: "8px", // Optional: rounded corners
    padding: "0px", // Optional: padding inside the container
    marginTop: "15px",
    height: "40%",
    minHeight: "240px",
    maxHeight: "241px",
    // overflowY: "scroll",
    overflow: "auto", // Hide scrollbars
  },
  subTableStyle: {
    minWidth: "740px", // Ensure table stretches horizontally
    maxHeight: "120px",
  },
  buttonDivStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  tableIStyle: {
    minWidth: "740px",
  },
};
