function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          data: datasets.data,
          maxBarThickness: 50,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              let value = tooltipItem.raw;
              return `₹ ${value.toLocaleString("en-IN")}`;
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 50000000, // Adjust according to expected max value
            beginAtZero: true,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "#fff",
            // Add a callback to format the y-axis values
            callback: function (value) {
              if (value >= 10000000) {
                return (value / 10000000).toFixed(1) + " Cr"; // Convert to crores
              } else if (value >= 100000) {
                return (value / 100000).toFixed(1) + " L"; // Convert to lakhs
              }
              return value;
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            display: true,
            color: "#f8f9fa",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}
export default configs;

// function configs(labels, datasets) {
//   return {
//     data: {
//       labels,
//       datasets: [
//         {
//           label: datasets.label,
//           tension: 0.4,
//           borderWidth: 0,
//           borderRadius: 4,
//           borderSkipped: false,
//           backgroundColor: "rgba(255, 255, 255, 0.8)",
//           data: datasets.data,
//           maxBarThickness: 6,
//         },
//       ],
//     },
//     options: {
//       responsive: true,
//       maintainAspectRatio: false,
//       plugins: {
//         legend: {
//           display: false,
//         },
//       },
//       interaction: {
//         intersect: false,
//         mode: "index",
//       },
//       scales: {
//         y: {
//           grid: {
//             drawBorder: false,
//             display: true,
//             drawOnChartArea: true,
//             drawTicks: false,
//             borderDash: [5, 5],
//             color: "rgba(255, 255, 255, .2)",
//           },
//           ticks: {
//             suggestedMin: 0,
//             suggestedMax: 500,
//             beginAtZero: true,
//             padding: 10,
//             font: {
//               size: 14,
//               weight: 300,
//               family: "Roboto",
//               style: "normal",
//               lineHeight: 2,
//             },
//             color: "#fff",
//           },
//         },
//         x: {
//           grid: {
//             drawBorder: false,
//             display: true,
//             drawOnChartArea: true,
//             drawTicks: false,
//             borderDash: [5, 5],
//             color: "rgba(255, 255, 255, .2)",
//           },
//           ticks: {
//             display: true,
//             color: "#f8f9fa",
//             padding: 10,
//             font: {
//               size: 14,
//               weight: 300,
//               family: "Roboto",
//               style: "normal",
//               lineHeight: 2,
//             },
//           },
//         },
//       },
//     },
//   };
// }

// export default configs;
