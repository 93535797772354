import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types"; // For prop-types validation
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // To save the file in the browser
import { format } from "date-fns"; // Optional for date formatting
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  TableContainer,
  Paper,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";
import "jspdf-autotable";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"; // Download icon for the button
import { monthEndStyles } from "components/Styles/monthEndStyles";
import useDsaAndEmployeeBusinessApi from "components/GlobalHooks/useMonthlyDsa&EmployeeBusiness";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { selectScrollStyle, CustomPaper } from "components/Styles/globalStyles";
export default function MonthlyDSABusiness() {
  const { monthIdData } = useBusinessMonthYear();
  const [monthId, setMonthId] = useState(0);
  const [dsaId, setDsaId] = useState(0);
  const businessCodeRef = useRef(0);
  const dsaIdref = useRef(0);
  const MInput = inputStyle();
  const {
    dsaData,
    loading,
    message,
    alertType,
    servicesData,
    employeeData,
    open,
    handleClose,
    setLoading,
    setAlert,
    setOpen,
    setMessage,
    fetchEntriesByMonthId,
  } = useDsaAndEmployeeBusinessApi("dsa");

  const handleCloseAlert = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (monthIdData?.length > 0 && dsaData?.length > 0) {
      const mId = monthIdData[0].businessMonthID;
      const dId = dsaData[0].dsaId;
      setMonthId(mId);
      setDsaId(dId);
      fetchEntriesByMonthId(mId, dId);
    }
  }, [monthIdData, dsaData]);
  useEffect(() => {
    businessCodeRef.current = monthId;
    dsaIdref.current = dsaId;
  }, [monthId, dsaId]);

  const ActionCellRenderer = (props) => {
    const handleDownload = async () => {
      if (!servicesData.length) return;

      // Create a new workbook
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Monthly DSA Business");

      // Define headers
      const headers = [
        "Date",
        "Current Status",
        "Appl. No.",
        "Business Name",
        "Provider Name",
        "Customer Name",
        "Main DSA",
        "Sales Manager",
        "DSA Sales Manager",
        "Sub DSA",
        "Applied Amount",
        "Disbursed Amount",
        "Gross Payout (%)",
        "Gross Payout Amount",
        "Final Gross Payout Amount",
        "DSA Payout (%)",
        "DSA Payout Amount",
        "Final DSA Gross Payout Amount",
        "Sub DSA Payout (%)",
        "Sub DSA Payment Amount",
      ];

      // Add headers to the worksheet
      worksheet.addRow(headers);

      // Style the header row
      const headerRow = worksheet.getRow(1);
      headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text
      headerRow.alignment = { horizontal: "center", vertical: "middle" };
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3a3aed" }, // Blue background
      };

      // Add data rows
      servicesData.forEach((row) => {
        worksheet.addRow([
          format(new Date(row.date), "dd-MM-yyyy"), // Date formatting
          row.currentStatus || "N/A",
          row.applicatioNumber || "N/A",
          row.businessName || "N/A",
          row.financialInstitutionName || "N/A",
          row.customerName || "N/A",
          row.mainDSA || "N/A",
          row.salesManager || "N/A",
          row.dsaSalesManager || "N/A",
          row.subDSA || "N/A",
          `₹ ${row.appliedAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `₹ ${row.disbursedAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `${row.grossPayoutPercent?.toFixed(2) || "0"}%`,
          `₹ ${row.grossPayoutAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `₹ ${row.finalGrossPayoutAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `${row.dsaPayoutPercent?.toFixed(2) || "0"}%`,
          `₹ ${row.dsaPayoutAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `₹ ${row.finalDsaGrossPayoutAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          `${row.subDsaPayoutPercent?.toFixed(2) || "0"}%`,
          `₹ ${row.subDsaPaymentAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        ]);
      });

      // Auto-fit columns based on content
      worksheet.columns.forEach((column) => {
        const maxLength = column.values.reduce(
          (max, val) => Math.max(max, val?.toString().length || 0),
          10
        );
        column.width = maxLength + 2; // Add padding
      });

      // Export the workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Monthly_DSA_Business_Entries_Data.xlsx");
    };
    return (
      <StyledButton onClick={handleDownload}>
        <DownloadForOfflineIcon fontSize="medium" />
      </StyledButton>
    );
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Current Status",
      field: "currentStatus",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        const statusMapping = {
          N: "New",
          D: "Disbursed",
          R: "Payment Received from Provider",
          C: "Paid to DSA",
          S: "Paid to Sub DSA", // Assuming 'R' for Sub DSA was a typo
        };
        return statusMapping[params.value] || "Unknown Status"; // Fallback for unmapped values
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Business Name",
      field: "businessName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Provider Name",
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 2,
    },
    {
      headerName: "Customer Name",
      field: "customerName",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "Main DSA",
      field: "mainDSA",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Sales Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
    },
    {
      headerName: "DSA Sales Manager",
      field: "dsaSalesManager",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      valueGetter: (params) => (params.data.dsaSalesManager ? params.data.dsaSalesManager : "N/A"),
    },
    {
      headerName: "Sub DSA",
      field: "subDSA",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => (params.data.subDSA ? params.data.subDSA : "N/A"),
    },
    {
      headerName: "Applied Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Disbursed Amount",
      field: "disbursedAmount",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Gross Payout (%)",
      field: "grossPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 180,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Gross Payout Amount",
      field: "grossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 230,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final Gross Payout Amount",
      field: "finalGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "DSA Payout (%)",
      field: "dsaPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 170,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "DSA Payout Amount",
      field: "dsaPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Final DSA Gross Payout Amount",
      field: "finalDsaGrossPayoutAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      headerName: "Sub DSA Payout (%)",
      field: "subDsaPayoutPercent",
      filter: true,
      sortable: true,
      minWidth: 200,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        params.value !== undefined && params.value !== null ? `${params.value.toFixed(2)}%` : "0",
    },
    {
      headerName: "Sub DSA Payment Amount",
      field: "subDsaPaymentAmount",
      filter: true,
      sortable: true,
      minWidth: 250,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${
          params.value
            ? params.value.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0"
        }`,
    },
  ];
  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };
  const triggerApiCall = () => {
    fetchEntriesByMonthId(businessCodeRef.current, dsaIdref.current);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date} // Display 'date' field from monthIdData
              value={monthIdData.find((item) => item.businessMonthID === monthId) || null} // Set the value based on monthId
              onChange={(event, newValue) => {
                if (newValue) {
                  setMonthId(newValue.businessMonthID);
                }
              }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.date.toLowerCase().startsWith(inputValue)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.date.toLowerCase().startsWith(inputValue) &&
                    option.date.toLowerCase().includes(inputValue)
                );

                // Combine the options with priority for startsWithInput
                return [...startsWithInput, ...containsInput];
              }}
              disableClearable={false}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "160px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              options={[{ dsaName: "All", dsaId: 0 }, ...dsaData]}
              getOptionLabel={(option) => option.dsaName} // Display 'dsaName' field from dsaData
              value={dsaData.find((item) => item.dsaId === dsaId) || { dsaName: "All", dsaId: 0 }} // Set the value based on dsaId
              onChange={(event, newValue) => {
                if (newValue) {
                  setDsaId(newValue.dsaId); // Update the selected DSA ID
                }
              }}
              PaperComponent={CustomPaper}
              disableClearable={false}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.dsaName.toLowerCase().startsWith(inputValue)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.dsaName.toLowerCase().startsWith(inputValue) &&
                    option.dsaName.toLowerCase().includes(inputValue)
                );

                // Combine the options with priority for startsWithInput
                return [...startsWithInput, ...containsInput];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dsa Name"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "200px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.dsaId === value.dsaId}
            />

            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>
          {ActionCellRenderer()}
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={servicesData}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
