import { useState, useEffect } from "react";
import Cookie from "js-cookie";

// Define the custom hook
const useBusinessMonthYear = () => {
  const [monthIdData, setMonthIdData] = useState([]); // State to hold the fetched data
  const [loadingBId, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const jwt_token = Cookie.get("jwt_token");

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt_token}`, // Include the JWT token here
        },
      };
      const response = await fetch(
        "https://185.193.19.156:8443/fintrack/businessMonthYear",
        options
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setMonthIdData(data); // Set the fetched data to state
      console.log("Fetched Data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlert("Error fetching data: " + error.message, "error"); // Set error alert
    } finally {
      setLoading(true); // End loading
    }
  };

  // Fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  return {
    monthIdData,
    loadingBId,
    message,
    alertType,
    open,
    handleClose,
    setLoading,
    setAlert,
    setOpen,
    setMessage,
    fetchData, // Return the fetchData method to call it externally
  };
};

export default useBusinessMonthYear;
