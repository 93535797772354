import { Route, Navigate } from "react-router-dom";
import Cookie from "js-cookie";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  const token = Cookie.get("access_token");
  // const token = Cookie.get("j_token");

  return token ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
