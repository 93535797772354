import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Table,
  TableBody,
  TableContainer,
  DialogContentText,
  TableRow,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import {
  receiptDialogStyles,
  typoStyle,
  mdBoxStyle,
  dialogContentStyle,
  containerStyle,
  tableCellStyle,
  tableCellFontStyle,
  subDialogContentStyle,
  dialogContentTextStyle,
} from "components/Styles/approvalStyles";
import { monthEndStyles } from "components/Styles/monthEndStyles";
import useApprovalEntriesApi from "../../../../customApiHooks/approvalApi";
import { CustomPaper } from "components/Styles/globalStyles";

export default function BankerPayoutReceipt() {
  const businessTypes = {
    Loans: "BL",
    Insurance: "IN",
    "Mutual Fund": "MF",
  };
  const labels = {
    BL: {
      name: "Banker Name",
      serviceAmount: "Loan Amount",
      serviceLabel: "Loan Type",
      finalServiceAmount: "Disbursed Amount",
    },
    IN: {
      name: "Provider Name",
      serviceAmount: "Premium Amount",
      serviceLabel: "Service Type",
      finalServiceAmount: "Premium Commenced Amount",
    },
    MF: {
      name: "Provider Name",
      serviceAmount: "Premium Amount",
      serviceLabel: "Service Type",
      finalServiceAmount: "Premium Commenced Amount",
    },
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [businessCode, setBusinessCode] = useState(businessTypes.Loans);
  const businessCodeRef = useRef(businessTypes.Loans);
  const [entryStatus, setEntryStatus] = useState("D");
  const MInput = inputStyle();
  const [selectedData, setSelectedData] = useState({
    finalGrossPayoutAmount: 0,
  });
  const {
    approveEntryApiCall,
    open,
    message,
    fetchEntrieById,
    businessEntry,
    fetchPendingEntries,
    pendingPaymentEntries,
    loading,
    setLoading,
    handleCloseAlert,
    showAlert,
    alertType,
    isEditMode,
    setIsEditMode,
  } = useApprovalEntriesApi(businessCodeRef.current, entryStatus);

  const handleViewClick = async (rowData) => {
    if (rowData.entryId) {
      await fetchEntrieById(rowData.entryId); // Directly use rowData.entryId
    }
    setSelectedData(rowData);
    setOpenPopup(true);
  };

  const handleBusinessCodeChange = (event) => {
    setBusinessCode(event.target.value);
  };

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
  }

  const ActionCellRenderer = (props) => {
    const rowData = props.api.getDisplayedRowAtIndex(props.node.rowIndex).data;
    return (
      <ViewButton type="button" onClick={() => handleViewClick(rowData)}>
        <VisibilityIcon />
      </ViewButton>
    );
  };

  const columns = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 120,
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: labels[businessCode].serviceAmount, // dynamic label for the column
      field: "appliedAmount", // this field holds the original numeric value
      filter: "agNumberColumnFilter", // using a number filter to filter by the original numeric value
      sortable: true, // allows sorting by the original numeric value
      minWidth: 150,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },

      // Use valueFormatter to format the displayed value (but filtering uses the original value)
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,

      headerClass: "header-center", // optional styling for the header
    },
    {
      headerName: "DSA Name",
      field: "mainDSA",
      filter: true,
      sortable: true,
      minWidth: 120,
      flex: 1.5,
    },
    {
      headerName: labels[businessCode].name,
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "View",
      cellRenderer: ActionCellRenderer,
      maxWidth: 90,
      flex: 1,
    },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseMd = () => {
    setOpenPopup(false);
  };

  const handleConfirmClick = async () => {
    const data = {
      ...businessEntry,
      finalGrossPayoutAmount: selectedData.finalGrossPayoutAmount,
      businessStatus: "R".charAt(0),
    };
    await approveEntryApiCall(selectedData.entryId, data);
    setOpenPopup(false);
    setOpenDialog(false);
    triggerApiCall();
    setSelectedData({
      finalGrossPayoutAmount: 0,
    });
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const triggerApiCall = () => {
    businessCodeRef.current = businessCode;
    fetchPendingEntries();
  };

  const handleFinalGrossPayoutAmountChange = (event) => {
    const value = event.target.value;
    // Allow clearing the input
    if (value === "") {
      setSelectedData({
        ...selectedData,
        finalGrossPayoutAmount: "",
      });
      return;
    }
    if (!/^\d*\.?\d*$/.test(value)) {
      showAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      if (numericValue <= selectedData.grossPayoutAmount) {
        setSelectedData({
          ...selectedData,
          finalGrossPayoutAmount: value,
        });
      } else if (numericValue > selectedData.grossPayoutAmount) {
        showAlert("Received amount must be less than or equal to Gross Payout Amount", "error");
      }
    }
  };
  const formattedAmount = selectedData.finalGrossPayoutAmount;
  // selectedData.disbursedAmount !== ""
  //   ? Number(selectedData.finalGrossPayoutAmount).toLocaleString("en-IN")
  //   : "";

  const handleOpenDialog = () => {
    if (selectedData.finalGrossPayoutAmount === 0) {
      showAlert("Enter Received Payout amount", "error");
      return;
    }
    setOpenDialog(true);
  };

  useEffect(() => {
    fetchPendingEntries();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={Object.entries(businessTypes).map(([key, value]) => ({
                  label: key,
                  value: value,
                }))}
                getOptionLabel={(option) => option.label}
                value={
                  businessCode && Object.values(businessTypes).includes(businessCode)
                    ? {
                        label: Object.keys(businessTypes).find(
                          (key) => businessTypes[key] === businessCode
                        ),
                        value: businessCode,
                      }
                    : null
                }
                disableClearable={false}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  // Filter options where the label starts with the input value
                  const startsWithInput = options.filter((option) =>
                    option.label.toLowerCase().startsWith(inputValue)
                  );
                  // Filter options where the label contains the input value but does not start with it
                  const containsInput = options.filter(
                    (option) =>
                      !option.label.toLowerCase().startsWith(inputValue) &&
                      option.label.toLowerCase().includes(inputValue)
                  );
                  // Combine and return the filtered results
                  return [...startsWithInput, ...containsInput];
                }}
                PaperComponent={CustomPaper}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setBusinessCode(newValue.value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Business Type"
                    variant="outlined"
                    required
                    sx={{
                      height: 43,
                      ...MInput.b,
                      ...monthEndStyles.textField,
                      "& .MuiInputBase-root": {
                        height: "100%",
                        minHeight: 43,
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: MInput.a,
                        "&.Mui-focused": {
                          color: "red", // Focused label color
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={pendingPaymentEntries}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          ...receiptDialogStyles,
        }}
      >
        <DialogTitle sx={{ position: "relative", padding: "0px", zIndex: "1000" }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
            sx={{ ...mdBoxStyle, backgroundColor: MInput.a }}
          >
            <Typography variant="h6" fontWeight="medium" color="#FFFFFF" {...typoStyle}>
              Payment Confirmation
            </Typography>
          </MDBox>
        </DialogTitle>
        <DialogContent {...dialogContentStyle}>
          <Grid container spacing={2} pt={2}>
            <StyledSnackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleCloseAlert}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              severity={alertType}
            >
              <StyledAlert onClose={handleCloseAlert} severity={alertType}>
                {message}
              </StyledAlert>
            </StyledSnackbar>
            <Grid item xs={12} md={3}>
              <TextField
                label="Insurance Id"
                fullWidth
                value={selectedData.entryId}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label="Date"
                fullWidth
                value={
                  selectedData?.date
                    ? formatTimestampToDate(selectedData.date)
                    : new Date().toLocaleDateString()
                }
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Application Number"
                fullWidth
                value={selectedData.applicatioNumber}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="DSA Name"
                fullWidth
                value={selectedData.mainDSA || "Unknown DSA"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Sub DSA"
                fullWidth
                value={selectedData.subDSA || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Andromeda Manager"
                fullWidth
                value={selectedData.salesManager || "Unknown Manager"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Dsa Sales Manager"
                fullWidth
                value={selectedData.dsaSalesManager || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={labels[businessCode].serviceLabel}
                fullWidth
                value={selectedData.businessName || "Unknown Policy"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Name"
                fullWidth
                value={selectedData.customerName}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={labels[businessCode].name}
                fullWidth
                value={selectedData.financialInstitutionName || "Unknown Bank"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label={labels[businessCode].serviceAmount}
                fullWidth
                value={`₹ ${selectedData?.appliedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label={labels[businessCode].finalServiceAmount}
                fullWidth
                value={`₹ ${selectedData?.disbursedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label="Remarks"
                multiline
                rows={1}
                fullWidth
                value={selectedData.remarks}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} {...containerStyle}>
            <Table>
              <TableBody>
                <TableRow {...tableCellStyle}>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    {/* Empty cell or any other content you need */}
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Payout %</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Calculated Payout Amount</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Actual Payout Amount</strong>
                    </Typography>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell minWidth="100px" flexGrow={1}>
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Provider</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1}>
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>{Number(selectedData.grossPayoutPercent).toFixed(2)}</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1}>
                    <TextField
                      label=""
                      fullWidth
                      value={`₹ ${selectedData?.grossPayoutAmount?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        inputProps: {
                          min: 0, // ensures only positive numbers can be entered
                          inputMode: "decimal", // allows numeric input with decimal on mobile
                        },
                        readOnly: true,
                      }}
                      sx={MInput.b}
                      InputLabelProps={{
                        sx: {
                          color: MInput.a,
                        },
                      }}
                    />
                  </CustomTableCell>
                  <CustomTableCell minWidth="100px" flexGrow={1}>
                    <TextField
                      label=""
                      fullWidth
                      value={formattedAmount || ""}
                      onChange={handleFinalGrossPayoutAmountChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        inputProps: {
                          min: 0, // ensures only positive numbers can be entered
                          inputMode: "decimal", // allows numeric input with decimal on mobile
                        },
                      }}
                      InputLabelProps={{
                        className:
                          selectedData.finalGrossPayoutAmount ||
                          selectedData.finalGrossPayoutAmount === 0
                            ? "inputLabelShrink"
                            : "inputLabel",
                        sx: {
                          color: MInput.a,
                        },
                      }}
                      error={selectedData.finalGrossPayoutAmount === ""} // Error state if required and value is empty
                      sx={MInput.b}
                    />
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to confirm payment received from
                ${selectedData.financialInstitutionName || "Unknown Bank"} for application number ${
                selectedData.applicatioNumber
              }?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={monthEndStyles.dialogActions}>
            <StyledButton onClick={handleConfirmClick}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDialog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <DialogActions>
          <StyledButton onClick={handleOpenDialog}>Confirm</StyledButton>
          <StyledButton onClick={handleCloseMd}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
BankerPayoutReceipt.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
