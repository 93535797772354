import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview({ monthlyOverViewData }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Monthly Overview
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24%
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pl={2}>
        <TimelineItem
          color="success"
          icon="credit_card_icon "
          title="Loans"
          totalApplications="100"
          rejected="20"
          revenue="1500000"
          monthlyOverviewD={monthlyOverViewData["BL"]}
          // dateTime="22 DEC 7:20 PM"
        />
        <TimelineItem
          color="secondary"
          icon="health_and_safety_icon"
          title="Insurance"
          totalApplications="90"
          rejected="10"
          revenue="1400000"
          monthlyOverviewD={monthlyOverViewData["IN"]}
          // dateTime="21 DEC 11 PM"
        />
        <TimelineItem
          color="info"
          icon="trending_up_icon"
          title="Mutual Funds"
          // dateTime="21 DEC 9:34 PM"
          totalApplications="90"
          rejected="20"
          revenue="1100000"
          monthlyOverviewD={monthlyOverViewData["MF"]}
          lastItem
        />
      </MDBox>
    </Card>
  );
}

OrdersOverview.propTypes = {
  monthlyOverViewData: PropTypes.shape({
    IN: PropTypes.shape({
      businessType: PropTypes.string,
      totalApplications: PropTypes.number,
      revenue: PropTypes.number,
      business: PropTypes.number,
      disbursedApplications: PropTypes.number,
    }),
    MF: PropTypes.shape({
      businessType: PropTypes.string,
      totalApplications: PropTypes.number,
      revenue: PropTypes.number,
      business: PropTypes.number,
      disbursedApplications: PropTypes.number,
    }),
    BL: PropTypes.shape({
      businessType: PropTypes.string,
      totalApplications: PropTypes.number,
      revenue: PropTypes.number,
      business: PropTypes.number,
      disbursedApplications: PropTypes.number,
    }),
  }).isRequired,
};
export default OrdersOverview;
