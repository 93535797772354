import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // For prop-types validation
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import URLs from "constants/urls";
import {
  TableContainer,
  Paper,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { writeFile, utils } from "xlsx"; // Import XLSX for Excel export
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"; // Download icon for the button
import { monthEndStyles } from "components/Styles/monthEndStyles";

import { CustomPaper } from "components/Styles/globalStyles";

export default function EmployeeProfitability() {
  const { monthIdData, message, alertType, setAlert, open, handleClose } = useBusinessMonthYear();
  const [rowData, setRowData] = useState([]);
  const [monthId, setMonthId] = useState(0); // This will track the selected monthId
  const [loading, setLoading] = useState(false);
  const MInput = inputStyle();

  // Function to fetch employee productivity data based on the selected monthId
  const fetchEmployeeProductivity = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${URLs.EmployeeProductivity}/${monthId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setRowData(data); // Set the fetched data in rowData
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlert("Error fetching data!", "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (monthIdData && monthIdData.length > 0) {
      // Set monthId only when monthIdData exists and is not empty
      setMonthId(monthIdData[0].businessMonthID);
      fetchEmployeeProductivity();
    }
  }, [monthIdData]);

  // Download button action for each row
  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      if (!rowData.length) return;
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(rowData); // Convert the entire rowData to a sheet
      utils.book_append_sheet(wb, ws, "EmployeeData");
      writeFile(wb, "Employee_Productivity_Data.xlsx"); // Save the workbook
    };

    return (
      <StyledButton onClick={handleDownload}>
        <DownloadForOfflineIcon fontSize="medium" />
      </StyledButton>
    );
  };

  // PropTypes validation for props in ActionCellRenderer
  ActionCellRenderer.propTypes = {
    data: PropTypes.shape({
      employeeName: PropTypes.string.isRequired,
    }).isRequired,
  };

  const triggerFetchCall = () => {
    fetchEmployeeProductivity();
  };

  const columnDefs = [
    {
      headerName: "Employee Name",
      field: "employeeName",
      minWidth: 150,
      flex: 1,
      filter: true,
      sortable: true,
    },
    { headerName: "Role", field: "roleName", minWidth: 140, flex: 1, filter: true, sortable: true },
    {
      headerName: "Salary",
      field: "salary",
      minWidth: 100,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Achieved Target",
      field: "achievedTarget",
      minWidth: 170,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Gross Profit",
      field: "grossProfit",
      minWidth: 140,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "TDS",
      field: "tds",
      minWidth: 100,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Net Profit",
      field: "netProfit",
      minWidth: 130,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Expenditure",
      field: "expenditure",
      minWidth: 140,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Productivity",
      field: "productivity",
      minWidth: 150,
      filter: true,
      flex: 1,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Productivity %",
      field: "productivityPercent",
      minWidth: 150,
      filter: true,
      flex: 1,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date} // Display 'date' field from monthIdData
              value={monthIdData.find((item) => item.businessMonthID === monthId) || null} // Set the value based on monthId
              onChange={(event, newValue) => {
                if (newValue) {
                  setMonthId(newValue.businessMonthID); // Update the selected month ID
                }
              }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                // Filter options that start with the input value
                const startsWithInput = options.filter((option) =>
                  option.date.toLowerCase().startsWith(inputValue)
                );

                // Filter options that contain the input value but do not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.date.toLowerCase().startsWith(inputValue) &&
                    option.date.toLowerCase().includes(inputValue)
                );

                // Combine the options with priority for startsWithInput
                return [...startsWithInput, ...containsInput];
              }}
              disableClearable={false}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "160px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
            />

            <StyledButton onClick={triggerFetchCall}>Get</StyledButton>
          </Box>
          {ActionCellRenderer()}
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
