import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useBusinessEntryApiCall from "../../../../customApiHooks/useBusinessEntryApiCall";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import PaymentIcon from "@mui/icons-material/Payment";
import { Tooltip } from "@mui/material";
import {
  Grid,
  MenuItem,
  TableContainer,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  DeleteButton,
  StyledTypography,
  StyledButton,
  StyledButtonC,
  StyledAlert,
  StyledSnackbar,
  StyledIconButton,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import { subDialogContentStyle, dialogContentTextStyle } from "components/Styles/approvalStyles";
import { tableShadow, globalStyles, CustomPaper } from "components/Styles/globalStyles";
import { entryStyles } from "components/Styles/loanEntryStyles";
import { sub } from "date-fns";
export default function LoanEntry() {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedDSA, setSelectedDSA] = useState("");
  const [selectedSubDSA, setSelectedSubDSA] = useState(0);
  const [selectedLoanType, setSelectedLoanType] = useState("");
  const [selectedAppliedTo, setSelectedAppliedTo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [providerPayout, setProviderPayout] = useState("");
  const [dsaPayout, setDSAPayout] = useState("");
  const [selectedDsaSalesManager, setSelectedDsaSalesManager] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // Set to current date by default
  const [LoanAmount, setLoanAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [entryId, setEntryId] = useState("");
  const [serviceEntryId, SetServiceEntryId] = useState("");
  const [subDsaPayout, setSubDsaPayout] = useState(0);
  const [subDsaId, setSubDsaId] = useState(0);
  const [serviceTypeCode, setServiceTypeCode] = useState("BL");
  const [grossPayoutAmount, setGrossPayoutAmount] = useState(0);
  const [dsaPayoutAmount, setDsaPayoutAmount] = useState(0);
  const [dsaStatus, setDsaStatus] = useState(false);
  const [businessMonthId, setBusinessMonthId] = useState(0);
  const [businessStatus, setBusinessStatus] = useState("N");
  const [filteredSubDsaManagers, setFilteredSubDsaManagers] = useState([]);
  const [dsaTdsPercent, setDsaTdsPercent] = useState();
  const [subDsaTdsPercent, setSubDsaTdsPercent] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [businessStatusFilter, setBusinessStatusFilter] = useState("All");
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    setLoanAmount(inputValue);
  };
  const MInput = inputStyle();
  const handleStatusChange = (event, value) => {
    setBusinessStatusFilter(value ? value.value : "");
  };
  const handleReset = () => {
    setSelectedGroup("");
    setSelectedManager("");
    setSelectedDSA("");
    setSelectedSubDSA(""); // Empty string to match type
    setSelectedLoanType("");
    setSelectedAppliedTo("");
    setCustomerName("");
    setProviderPayout("");
    setDSAPayout("");
    setSelectedDsaSalesManager("");
    setApplicationNumber("");
    setLoanAmount("");
    setRemarks("");
    setSubDsaPayout("");
    setEditStatus(false);
    setDate(new Date().toISOString().slice(0, 10)); // Reset to current date if needed
    setEntryId("");
    setGrossPayoutAmount(0);
    setDsaPayoutAmount(0);
    setDsaStatus(false);
    setBusinessStatus("N");
    setDsaTdsPercent("");
    setSubDsaTdsPercent("");
  };
  const inputStatus = businessStatus == "C" || businessStatus == "R" || businessStatus == "S";
  const {
    businessMasterData,
    providerData,
    dsaData,
    entryData,
    subDsaData,
    managersData,
    dsaManagersData,
    message,
    alertType,
    open,
    editStatus,
    loading,
    finalFinanceData,
    setLoading,
    setEditStatus,
    fetchEntriesFeildsData,
    fetchEntriesByTypeCode,
    fetchFilteredFinanceDetails,
    fetchBusinessEntriesApiCall,
    fetchFinanceDetails,
    postEntry,
    putEntry,
    deleteEntry,
    setAlert,
    handleClose,
  } = useBusinessEntryApiCall(serviceTypeCode, handleReset);

  const filteredDsaMngrFunction = useCallback(() => {
    if (selectedSubDSA) {
      return dsaManagersData.filter((item) => item.companyId === selectedSubDSA);
    }
    return [];
  }, [selectedSubDSA, dsaManagersData]);

  useEffect(() => {
    if (selectedLoanType) {
      fetchFinanceDetails(selectedLoanType);
    }
  }, [selectedLoanType]);

  useEffect(() => {
    if (selectedDSA) {
      const selectedDsa = dsaData.find((dsa) => dsa.dsaId === parseInt(selectedDSA));
      if (selectedDsa) {
        setDsaStatus(selectedDsa.dsaStatus == "S");
      } else {
        setDsaStatus(false); // Reset if no DSA is found
        setSelectedDsaSalesManager(0);
        setDSAPayout(0);
        setSubDsaId(0);
      }
    } else {
      setDsaStatus(false); // Reset if no DSA is selected
    }
  }, [selectedDSA]);

  // Effect to update filtered managers when selectedSubDSA changes
  useEffect(() => {
    const filteredData = filteredDsaMngrFunction();
    setFilteredSubDsaManagers(filteredData); // Update filtered managers state
  }, [filteredDsaMngrFunction, selectedSubDSA]);

  useEffect(() => {
    if (!editStatus && finalFinanceData.length > 0) {
      const selectedFinance = finalFinanceData.find(
        (eachEntry) => eachEntry.finananceId == selectedAppliedTo
      );

      if (selectedFinance) {
        setProviderPayout(selectedFinance.grossPayOut);
        setDSAPayout(selectedFinance.dsaPayOut);
      }
    }
  }, [selectedAppliedTo, finalFinanceData, editStatus]);

  useEffect(() => {
    if (!dsaStatus) {
      setSelectedSubDSA(0);
      setSelectedDsaSalesManager(0);
      setSubDsaPayout("");
    }
  }, [dsaStatus]);
  const handleFilter = useCallback(() => {
    if (businessStatusFilter == "All") {
      fetchBusinessEntriesApiCall();
    } else {
      fetchFilteredFinanceDetails(serviceTypeCode, businessStatusFilter);
    }
  }, [businessStatusFilter]);

  const postLoanEntryApiCall = () => {
    const data = {
      entryId: 0,
      dsaId: parseInt(selectedDSA),
      subdsaId: parseInt(selectedSubDSA) || 0,
      applicationNumber: applicationNumber,
      businessType: serviceTypeCode,
      date: date,
      dsaEmployeeId: selectedDsaSalesManager || 0,
      businessMonthId: parseInt(businessMonthId),
      employeeId: parseInt(selectedManager),
      businessId: parseInt(selectedLoanType),
      customerName: customerName,
      finananceId: parseInt(selectedAppliedTo), // Corrected key
      appliedAmount: parseFloat(LoanAmount),
      grossPayout: parseFloat(providerPayout),
      grossPayoutAmount: parseFloat(grossPayoutAmount), // Added missing field
      dsaPayout: parseFloat(dsaPayout),
      dsaPayoutAmount: parseFloat(dsaPayoutAmount), // Added missing field
      subdsaPayout: parseFloat(subDsaPayout) || 0,
      remarks: remarks,
      businessStatus: businessStatus.charAt(0),
      disbursedAmount: parseFloat(0),
      dsaTdsPercent: parseFloat(dsaTdsPercent) || 0,
      subDsaTdsPercent: parseFloat(subDsaTdsPercent) || 0,
    };
    postEntry(data);
  };
  const putLoanEntryApiCall = () => {
    const data = {
      entryId: parseInt(entryId),
      dsaId: parseInt(selectedDSA),
      subdsaId: parseInt(selectedSubDSA) || 0,
      applicationNumber: applicationNumber,
      businessType: serviceTypeCode,
      date: date,
      dsaEmployeeId: selectedDsaSalesManager || 0,
      businessMonthId: parseInt(businessMonthId),
      employeeId: parseInt(selectedManager),
      businessId: parseInt(selectedLoanType),
      customerName: customerName,
      finananceId: parseInt(selectedAppliedTo),
      appliedAmount: parseFloat(LoanAmount),
      grossPayout: parseFloat(providerPayout),
      grossPayoutAmount: parseFloat(grossPayoutAmount),
      dsaPayout: parseFloat(dsaPayout),
      dsaPayoutAmount: parseFloat(dsaPayoutAmount),
      subdsaPayout: parseFloat(subDsaPayout) || 0,
      remarks: remarks,
      businessStatus: businessStatus.charAt(0),
      disbursedAmount: parseFloat(0),
      dsaTdsPercent: parseFloat(dsaTdsPercent) || 0,
      subDsaTdsPercent: parseFloat(subDsaTdsPercent) || 0,
    };
    putEntry(entryId, data);
  };
  const deleteLoanEntryApiCall = (lEntryId) => {
    deleteEntry(lEntryId);
  };
  const handleEditLoanRow = (rowData) => {
    setEditStatus(true);
    setApplicationNumber(rowData.applicationNumber);
    setLoanAmount(rowData.appliedAmount);
    setSelectedLoanType(rowData.businessId);
    setCustomerName(rowData.customerName);
    setDate(formatTimestampToDate(rowData.date));
    setSelectedDSA(rowData.dsaId);
    setDSAPayout(rowData.dsaPayout);
    setSelectedManager(rowData.employeeId);
    setSelectedDsaSalesManager(rowData.dsaEmployeeId || 0);
    setEntryId(rowData.entryId);
    setSelectedAppliedTo(rowData.finananceId);
    setProviderPayout(rowData.grossPayout);
    setRemarks(rowData.remarks);
    setSelectedSubDSA(rowData.subdsaId);
    setSubDsaPayout(rowData.subdsaPayout);
    setSelectedGroup("1");
    setBusinessStatus(rowData.businessStatus);
    setBusinessMonthId(rowData.businessMonthId);
    setSubDsaTdsPercent(rowData.subDsaTdsPercent);
    setDsaTdsPercent(rowData.dsaTdsPercent);
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (editStatus) {
      putLoanEntryApiCall();
    } else {
      postLoanEntryApiCall();
    }
  };
  const resetFormState = () => {
    handleReset();
    setEditStatus(false);
  };
  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format it as "YYYY-MM-DD"
  }
  function openDailogDeleteBox(fEntryId) {
    setOpenDialog(true);
    SetServiceEntryId(fEntryId);
  }
  function handleConfirmDelete() {
    deleteLoanEntryApiCall(serviceEntryId); // Only delete if entryId exists
    setTimeout(() => {
      setOpenDialog(false);
    }, 1000);
  }
  const ActionCellRenderer = (props) => {
    const rowIndex = props.node.rowIndex;
    let rowData = null;

    if (props.api && props.api.getDisplayedRowAtIndex(rowIndex)) {
      rowData = props.api.getDisplayedRowAtIndex(rowIndex).data;
    } else {
      console.error("API or row data is not available.");
    }

    const handleEditClick = () => {
      if (rowData) {
        handleEditLoanRow(rowData); // Use the row data only if it exists
        const alertStatus =
          rowData.businessStatus === "C" ||
          rowData.businessStatus === "R" ||
          rowData.businessStatus === "S";
        if (alertStatus) {
          setAlert("Closed business cannot be edited.", "error");
        }
      } else {
        console.warn("Row data is not available for editing.");
      }
    };

    const handleDeleteClick = () => {
      if (rowData && rowData.entryId) {
        openDailogDeleteBox(rowData.entryId);
      } else {
        console.warn("Row data or entry ID is not available for deletion.");
      }
    };

    // Move text declaration inside the renderStatusIcon function
    const renderStatusIcon = () => {
      if (!rowData) return null;

      let iconColor; // Define a variable for icon color
      let icon; // Define a variable for the icon
      let statusText; // Define a variable for status text

      switch (rowData.businessStatus) {
        case "N":
          icon = <PendingIcon />; // Replace with the pending icon you want to use
          iconColor = "#FFA500"; // Orange for Pending
          statusText = "New";
          break;
        case "D":
          icon = <CheckCircleIcon />;
          iconColor = "#4CAF50"; // Green for Approved
          statusText = "Disbursed";
          break;
        case "R":
          icon = <PaymentIcon />;
          iconColor = "#1da1f2"; // Blue for Money Received
          statusText = "Payout Received From Provider";
          break;
        case "C":
          icon = <PaymentIcon />;
          iconColor = "#ff4d4d"; // Red for Commission Paid
          statusText = "Paid to DSA";
          break;
        case "S":
          icon = <PaymentIcon />;
          iconColor = "#ff9800";
          statusText = "Paid to  Sub DSA";
          break;
        default:
          icon = null; // Or a default icon if needed
          iconColor = "#000"; // Default color if no status matches
          statusText = "Unknown Status"; // Optional: default text
          break;
      }
      return {
        icon: (
          <StyledIconButton type="button" color={iconColor}>
            {icon}
          </StyledIconButton>
        ),
        text: statusText, // Return the text along with the icon
      };
    };

    const { icon, text } = renderStatusIcon(); // Destructure icon and text

    return (
      <div>
        <Tooltip title={text}>{icon}</Tooltip>
        <ViewButton type="button" onClick={handleEditClick}>
          <EditIcon />
        </ViewButton>
        <DeleteButton
          type="button"
          onClick={() => {
            if (rowData && rowData.businessStatus !== "N" && rowData.businessStatus !== "D") {
              setAlert(
                `${rowData.applicationNumber} business is closed. You cannot delete`,
                "error"
              );
            } else {
              handleDeleteClick();
            }
          }}
        >
          <DeleteIcon />
        </DeleteButton>
      </div>
    );
  };
  const columns = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sorted: true,
      minWidth: 50,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicationNumber",
      filter: true,
      sorted: true,
      minWidth: 70,
      flex: 1,
    },
    {
      headerName: "Loan Amount",
      field: "appliedAmount",
      filter: true,
      sortable: true,
      minWidth: 100,
      flex: 1.1,
      cellStyle: { textAlign: "right", fontWeight: "500" }, // Aligns the text to the right
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    { headerName: "Actions", cellRenderer: ActionCellRenderer, minWidth: 150, flex: 1 },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer component={Paper} sx={globalStyles.mainTable}>
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          aria-labelledby="loading-dialog-title"
        >
          <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="primary" />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Loading, please wait...
            </Typography>
          </DialogContent>
        </Dialog>
        <Dialog open={openDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to delete`}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={entryStyles.dialogActions}>
            <StyledButton onClick={handleConfirmDelete}>Yes</StyledButton>
            <StyledButton onClick={() => setOpenDialog(false)} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <TableContainer component={Paper} sx={{ ...tableShadow, ...entryStyles.marginStyle }}>
              <div style={entryStyles.divStyle}>
                <StyledTypography>
                  {!editStatus ? "Loan Entry Form" : "Edit Loan Entry Details"}
                </StyledTypography>
                {editStatus && (
                  <Tooltip title="Reset">
                    <StyledButtonC onClick={resetFormState}>
                      <RestartAltIcon />
                    </StyledButtonC>
                  </Tooltip>
                )}
              </div>
              <StyledSnackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                severity={alertType}
              >
                <StyledAlert onClose={handleClose} severity={alertType}>
                  {message}
                </StyledAlert>
              </StyledSnackbar>
              <form onSubmit={handleSave} autoComplete="off">
                <MDBox pt={3} pb={3} px={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="date"
                        type="date"
                        fullWidth
                        required
                        value={date || new Date().toISOString().split("T")[0]} // Set default value to today's date
                        onChange={(e) => setDate(e.target.value)}
                        InputProps={{
                          readOnly: inputStatus,
                          inputProps: {
                            min: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                              .toISOString()
                              .split("T")[0], // First day of current month
                            max: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                              .toISOString()
                              .split("T")[0], // Last day of current month
                          },
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <TextField
                        label="Customer Name"
                        fullWidth
                        required
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Application Number"
                        fullWidth
                        required
                        value={applicationNumber}
                        onChange={(e) => setApplicationNumber(e.target.value.trim())}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Loan Amount"
                        fullWidth
                        required
                        value={LoanAmount}
                        onChange={handleInputChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          inputProps: {
                            min: 0, // ensures only positive numbers can be entered
                            inputMode: "decimal", // allows numeric input with decimal on mobile
                          },
                          readOnly: inputStatus,
                        }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={managersData}
                        getOptionLabel={(option) =>
                          option.employeeFirstName + " " + option.employeeLastName
                        }
                        value={
                          managersData.some((item) => item.employeeId === selectedManager)
                            ? managersData.find((item) => item.employeeId === selectedManager)
                            : null
                        }
                        onChange={(event, value) =>
                          setSelectedManager(value ? value.employeeId : "")
                        }
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            (option.employeeFirstName + " " + option.employeeLastName)
                              .toLowerCase()
                              .startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !(option.employeeFirstName + " " + option.employeeLastName)
                                .toLowerCase()
                                .startsWith(input) &&
                              (option.employeeFirstName + " " + option.employeeLastName)
                                .toLowerCase()
                                .includes(input)
                          );

                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Andromeda Manager"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={dsaData}
                        getOptionLabel={(option) => option.dsaName}
                        value={
                          dsaData.some((item) => item.dsaId === selectedDSA)
                            ? dsaData.find((item) => item.dsaId === selectedDSA)
                            : null
                        }
                        onChange={(event, value) => setSelectedDSA(value ? value.dsaId : "")}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Separate options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.dsaName.toLowerCase().startsWith(input)
                          );

                          const containsInput = options.filter(
                            (option) =>
                              !option.dsaName.toLowerCase().startsWith(input) &&
                              option.dsaName.toLowerCase().includes(input)
                          );
                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select DSA"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>
                    {dsaStatus && (
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          options={subDsaData}
                          getOptionLabel={(option) => option.dsaName}
                          value={
                            subDsaData.some((item) => item.dsaId === selectedSubDSA)
                              ? subDsaData.find((item) => item.dsaId === selectedSubDSA)
                              : null
                          }
                          onChange={(event, value) => setSelectedSubDSA(value ? value.dsaId : "")}
                          disableClearable={false}
                          filterOptions={(options, state) => {
                            const input = state.inputValue.toLowerCase();
                            // Separate options into those that start with the input and those that contain it
                            const startsWithInput = options.filter((option) =>
                              option.dsaName.toLowerCase().startsWith(input)
                            );
                            const containsInput = options.filter(
                              (option) =>
                                !option.dsaName.toLowerCase().startsWith(input) &&
                                option.dsaName.toLowerCase().includes(input)
                            );
                            // Combine the options with those starting with the input first, then the rest
                            return [...startsWithInput, ...containsInput];
                          }}
                          PaperComponent={CustomPaper}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub DSA"
                              variant="outlined"
                              required
                              sx={{
                                height: 43,
                                ...MInput.b,
                                "& .MuiInputBase-root": {
                                  height: "100%",
                                  minHeight: 43,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  color: MInput.a, // Ensure this applies to the label
                                  "&.Mui-focused": {
                                    color: "red", // Focused label color
                                  },
                                },
                              }}
                            />
                          )}
                          disabled={inputStatus}
                        />
                      </Grid>
                    )}
                    {dsaStatus && (
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          options={filteredSubDsaManagers}
                          getOptionLabel={(option) =>
                            option.employeeFirstName + " " + option.employeeLastName
                          }
                          value={
                            filteredSubDsaManagers.some(
                              (item) => item.employeeId === selectedDsaSalesManager
                            )
                              ? filteredSubDsaManagers.find(
                                  (item) => item.employeeId === selectedDsaSalesManager
                                )
                              : null
                          }
                          onChange={(event, value) =>
                            setSelectedDsaSalesManager(value ? value.employeeId : "")
                          }
                          filterOptions={(options, state) => {
                            const input = state.inputValue.toLowerCase();
                            // Separate options into those that start with the input and those that contain it
                            const startsWithInput = options.filter((option) =>
                              (option.employeeFirstName + " " + option.employeeLastName)
                                .toLowerCase()
                                .startsWith(input)
                            );
                            const containsInput = options.filter(
                              (option) =>
                                !(option.employeeFirstName + " " + option.employeeLastName)
                                  .toLowerCase()
                                  .startsWith(input) &&
                                (option.employeeFirstName + " " + option.employeeLastName)
                                  .toLowerCase()
                                  .includes(input)
                            );
                            // Combine the options with those starting with the input first, then the rest
                            return [...startsWithInput, ...containsInput];
                          }}
                          disableClearable={false}
                          PaperComponent={CustomPaper}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select DSA Sales Manager"
                              variant="outlined"
                              required
                              sx={{
                                height: 43,
                                ...MInput.b,
                                "& .MuiInputBase-root": {
                                  height: "100%",
                                  minHeight: 43,
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  color: MInput.a, // Ensure this applies to the label
                                  "&.Mui-focused": {
                                    color: "red", // Focused label color
                                  },
                                },
                              }}
                            />
                          )}
                          disabled={inputStatus}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={dsaStatus ? 4.5 : 4}>
                      <Autocomplete
                        options={businessMasterData}
                        getOptionLabel={(option) => option.businessName}
                        value={
                          businessMasterData.some((item) => item.businessID === selectedLoanType)
                            ? businessMasterData.find(
                                (item) => item.businessID === selectedLoanType
                              )
                            : null
                        }
                        onChange={(event, value) => {
                          setSelectedLoanType(value ? value.businessID : "");
                        }}
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();
                          // Separate the options into those that start with the input and those that contain it
                          const startsWithInput = options.filter((option) =>
                            option.businessName.toLowerCase().startsWith(input)
                          );
                          const containsInput = options.filter(
                            (option) =>
                              !option.businessName.toLowerCase().startsWith(input) &&
                              option.businessName.toLowerCase().includes(input)
                          );
                          // Combine the options with those starting with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Loan Type"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>
                    <Grid item xs={12} md={dsaStatus ? 4.5 : 4}>
                      <Autocomplete
                        options={finalFinanceData}
                        getOptionLabel={(option) => option.finainacialInstitutionName}
                        value={
                          finalFinanceData.some((item) => item.finananceId === selectedAppliedTo)
                            ? finalFinanceData.find(
                                (item) => item.finananceId === selectedAppliedTo
                              )
                            : null
                        }
                        onChange={(event, value) =>
                          setSelectedAppliedTo(value ? value.finananceId : "")
                        }
                        disableClearable={false}
                        filterOptions={(options, state) => {
                          const input = state.inputValue.toLowerCase();

                          // Filter options that start with the input value
                          const startsWithInput = options.filter((option) =>
                            option.finainacialInstitutionName.toLowerCase().startsWith(input)
                          );

                          // Filter options that contain the input value but do not start with it
                          const containsInput = options.filter(
                            (option) =>
                              !option.finainacialInstitutionName.toLowerCase().startsWith(input) &&
                              option.finainacialInstitutionName.toLowerCase().includes(input)
                          );

                          // Combine the options: ones that start with the input first, then the rest
                          return [...startsWithInput, ...containsInput];
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Banker Name"
                            variant="outlined"
                            required
                            sx={{
                              height: 43,
                              ...MInput.b,
                              "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: 43,
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                color: MInput.a, // Ensure this applies to the label
                                "&.Mui-focused": {
                                  color: "red", // Focused label color
                                },
                              },
                            }}
                          />
                        )}
                        disabled={inputStatus}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Provider Payout %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={providerPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setProviderPayout(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }} // Limit the total length to 5 characters
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="DSA Payout %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={dsaPayout}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setDSAPayout(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }}
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="DSA TDS %"
                        type="number" // Use "text" to handle custom validation for the format
                        fullWidth
                        required
                        value={dsaTdsPercent}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Regex to match the format X.yyyy where X < 9 and yyyy can be 1-3 digits
                          if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                            setDsaTdsPercent(value);
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          readOnly: inputStatus,
                        }}
                        placeholder="0.000" // Example format
                        sx={MInput.b}
                        InputLabelProps={{
                          shrink:
                            dsaTdsPercent !== "" &&
                            dsaTdsPercent !== null &&
                            dsaTdsPercent !== undefined,
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                    {dsaStatus && (
                      <Grid item xs={12} md={3}>
                        <TextField
                          label="Sub DSA Payout %"
                          type="text" // Use "text" to handle custom validation for the format
                          fullWidth
                          required
                          value={subDsaPayout}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                              setSubDsaPayout(value);
                            }
                          }}
                          inputProps={{
                            maxLength: 5,
                            readOnly: inputStatus,
                          }}
                          placeholder="0.000" // Example format
                          sx={MInput.b}
                          InputLabelProps={{
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {dsaStatus && (
                      <Grid item xs={12} md={3}>
                        <TextField
                          label="Sub DSA TDS %"
                          type="text" // Use "text" to handle custom validation for the format
                          fullWidth
                          required
                          value={subDsaTdsPercent}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,2}(\.\d{0,3})?$/.test(value)) {
                              setSubDsaTdsPercent(value);
                            }
                          }}
                          inputProps={{
                            maxLength: 5,
                            readOnly: inputStatus,
                          }}
                          placeholder="0.000" // Example format
                          sx={MInput.b}
                          InputLabelProps={{
                            shrink:
                              subDsaTdsPercent !== "" &&
                              subDsaTdsPercent !== null &&
                              subDsaTdsPercent !== undefined,
                            sx: {
                              color: MInput.a,
                            },
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        label="Remarks"
                        fullWidth
                        multiline
                        rows={2}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        InputProps={{ readOnly: inputStatus }}
                        sx={MInput.b}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={3} mb={0} display="flex" justifyContent="flex-end">
                    <StyledButton disabled={inputStatus} type="submit">
                      Save
                    </StyledButton>
                  </MDBox>
                </MDBox>
              </form>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <TableContainer
              // sx={{ ...tableShadow }}
              component={Paper}
              sx={entryStyles.tableContainertwoStyle}
            >
              <MDBox sx={entryStyles.mdBoxStyle}>
                <MDBox mt={1} mb={2} sx={entryStyles.subMdBoxStyle}>
                  <Autocomplete
                    options={[
                      { label: "All", value: "All" },
                      { label: "New", value: "N" },
                      { label: "Disbursed", value: "D" },
                      { label: "Payout Received From Provider", value: "R" },
                      { label: "Paid to DSA", value: "C" },
                      { label: "Paid to Sub DSA", value: "S" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={
                      [
                        { label: "All", value: "All" },
                        { label: "New", value: "N" },
                        { label: "Disbursed", value: "D" },
                        { label: "Payout Received From Provider", value: "R" },
                        { label: "Paid to DSA", value: "C" },
                        { label: "Paid to Sub DSA", value: "S" },
                      ].find((option) => option.value === businessStatusFilter) || null
                    }
                    onChange={handleStatusChange}
                    disableClearable={false}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Business Status"
                        variant="outlined"
                        required
                        sx={{
                          height: 42,
                          ...MInput.b,
                          ...entryStyles.autoEntrySelectStyle,
                          "& .MuiInputBase-root": {
                            height: "100%",
                            minHeight: 42,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: MInput.a, // Ensure this applies to the label
                            "&.Mui-focused": {
                              color: "red", // Focused label color
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <StyledButton onClick={handleFilter}> Get</StyledButton>
                </MDBox>
              </MDBox>
              <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
                <AgGridReact
                  rowData={entryData}
                  columnDefs={columns}
                  pagination={true}
                  paginationPageSize={11}
                />
              </StyledAgGridContainer>
            </TableContainer>
          </Grid>
        </Grid>
      </TableContainer>
    </DashboardLayout>
  );
}

LoanEntry.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  uuid: PropTypes.string.isRequired, // Add uuid to propTypes
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
