import { selectScrollStyle } from "./globalStyles";

export const masterStyles = {
  root: {
    height: "100%", // Full height of the container
    minHeight: "300px",
    maxHeight: "620px",
    marginTop: "10px",
    overflowY: "auto",
    padding: "15px",
    flexGrow: 1,
  },
  tableContainer: {
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2), 0 4px 8px -2px rgba(0,0,0,0.2)",
    borderRadius: "8px",
    padding: "0px",
    marginTop: "15px",
    width: "100% !important", // Ensure it always occupies full width
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box", // Include padding and border in the element's total width
  },
  tableIStyle: {
    width: "100% !important",
    display: "flex",
    flexGrow: 1,
  },
  tableContainerT2: {
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2), 0 4px 8px -2px rgba(0,0,0,0.2)",
    borderRadius: "8px",
    padding: "0px",
    marginTop: "15px",
    width: "100% !important", // Ensure it always occupies full width
    display: "flex",
    flexDirection: "row",
    ...selectScrollStyle,
  },
  tableIStyleT2: {
    width: "70vw",
    minWidth: "300px",
    maxWidth: "900px",
    flexGrow: 1,
  },
  girdClass: {
    height: "98%",
    minHeight: "300px",
    marginTop: "20px",
    minwidth: "100%",
    flexGrow: 1,
  },
  selectClass: {
    width: "30vw",
    minWidth: "100px",
    maxWidth: "180px",
    overflow: "hidden",
  },
  selectClassAuto: {
    width: "30vw",
    minWidth: "100px",
    maxWidth: "180px",
    overflow: "hidden",
  },
  marginStyle: {
    marginTop: "0px",
  },
  tableHead: {
    position: "sticky",
    top: 0,
    alignItems: "stretch",
    backgroundColor: "#ffffff", // Ensure the header stays visible
    zIndex: 10,
  },
  divStyle: {
    position: "relative",
    width: "100%",
  },
  buttonDivStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    marginBottom: "16px",
  },
};
