export const dsaStyles = {
  root: {
    height: "75vh",
    minHeight: "600px",
    maxHeight: "640px",
    marginTop: "0px",
    overflowY: "auto",
    padding: "15px",
  },
  labelClass: {
    backgroundColor: "#FFFFFF",
  },
  tableContainer2: {
    height: "83vh", // Set your desired height
    marginTop: "16px",
    maxHeight: "800px",
    width: "100%", // Set your desired width
    padding: "10px",
    boxShadow: "none", // Remove default shadow from Paper
  },
  mainCard: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  selectClass: {
    height: "40px", // Set height of the select box
    "& .MuiSelect-select": {
      height: "40px", // Ensure content fits the height
      display: "flex",
      alignItems: "center",
    },
    fontSize: {
      xs: "0.75rem",
      sm: "0.8rem",
      md: "0.8rem",
      fontWeight: "400",
    },
    overflow: "hidden",
  },
  divStyle: {
    position: "relative",
    width: "100%",
  },
  tableServicesStyle: {
    boxShadow: "0 -4px 8px -2px rgba(0,0,0,0.2), 0 4px 8px -2px rgba(0,0,0,0.2)", // Top and bottom shadow
    borderRadius: "8px", // Optional: rounded corners
    padding: "0px", // Optional: padding inside the container
    marginTop: "15px",
    height: "40%",
    minHeight: "240px",
    maxHeight: "241px",
    // overflowY: "scroll",
    overflow: "auto", // Hide scrollbars
  },
  subTableStyle: {
    minWidth: "740px", // Ensure table stretches horizontally
    maxHeight: "120px",
  },
  buttonDivStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  card: {
    display: "flex",
    width: "100%",
    minWidth: "300px",
    maxWidth: "450px",
    justifyContent: "space-around",
    paddingTop: "20px",
    paddingBottom: "15px",
    flexWrap: "wrap",
    gap: "10px", // Minimum gap between elements
  },
  tableIStyle: {
    minWidth: "740px",
  },
  label: {
    backgroundColor: "#ffffff",
  },
  nameField: {
    height: "40px",
    width: "170px",
  },
  yearField: {
    height: "40px",
    width: "160px",
  },
  formItem: {
    marginTop: "8px",
    marginLeft: "8px",
    marginBottom: "8px",
    display: "flex",
    width: "45%",
    minWidth: "300px",
    paddingTop: "4px",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
  },
};
