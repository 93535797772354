import { Password } from "@mui/icons-material";
// https://185.193.19.156:8443/fintrack/employeeDetails
// https://stage-javaapi.bridgeconnect-runs.com/fintrack/employeeDetails
// const mainBaseUrl = "https://stage-javaapi.bridgeconnect-runs.com/fintrack";
// const mainBaseUrl = "https://prod-javaapi.bridgeconnect-runs.com/fintrack";
const mainBaseUrl = "https://185.193.19.156:8443/fintrack";
// const mainBaseUrl = "http://185.193.19.156:9091/fintrack";
const AccountUrl = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountsMaster";
const AccountsEntry = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountsEntry";
const AccountsReports = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountReports";
const URLs = {
  logiApiUrl: `${mainBaseUrl}/validate`,
  companyConfigurationUrl: `${mainBaseUrl}/companyDetails`,
  employeeMasterGetUrl: `${mainBaseUrl}/employeeDetails`,
  dsaMasterGetUrl: `${mainBaseUrl}/dsaDetails`,
  roleGetUrl: `${mainBaseUrl}/roleDetails`,
  finanaceGetUrlByTypeCodeUrl: `${mainBaseUrl}/finance`,
  financeBusinessGetUrl: `${mainBaseUrl}/businessDetails`,
  financeUrl: `${mainBaseUrl}/financeDetails`,
  entriesUrlById: `${mainBaseUrl}/financeDetailsByBusinessId`,
  baseUrl: mainBaseUrl,
  employeeDetailsByRoleIdUrl: `${mainBaseUrl}/employeesByRoleId`,
  businessEntriesUrl: `${mainBaseUrl}/businessentries`,
  monthEndUrl: `${mainBaseUrl}/monthEndDetails`,
  monthlyBusinessUrl: `${mainBaseUrl}/monthlyBusiness`,
  financeGetAllUrl: `${mainBaseUrl}/financeDetails`,
  accountsUrl: `${AccountUrl}/GetCashBankAccounts`, // External URL remains unchanged
  appAccessURl: `${mainBaseUrl}/details`,
  accessControlApi: `${mainBaseUrl}/loginEmployeeDetails`,
  PasswordUpdate: `${mainBaseUrl}/update`,
  removeAccess: `${mainBaseUrl}/delete`,
  removeRemaining: `${mainBaseUrl}/delete`,
  ParentGroupUrl: `${AccountUrl}/getParentGroups`,
  AllGroupUrl: `${AccountUrl}/getAllGroups`,
  PostAccountGroupUrl: `${AccountUrl}/postAccountGroup`,
  GetAccountsUrl: `${AccountUrl}/GetAccounts`,
  PostAccountLedger: `${AccountUrl}/postAccountLedger`,
  GetCashBankAccounts: `${AccountUrl}/GetCashBankAccounts`,
  GetCompanies: `${AccountUrl}/GetCompanies`,
  GetPaymentAccounts: `${AccountUrl}/GetPaymentAccounts`,
  PostVoucherEntry: `${AccountUrl}/PostVoucherEntry`,
  GetJVItem: `${AccountsEntry}/GetJVItem`,
  PostVoucherEntry: `${AccountsEntry}/PostVoucherEntry`,
  GetVoucherEntryList: `${AccountsEntry}/GetVoucherEntryList`,
  GetLedgerAccounts: `${AccountUrl}/GetLedgerAccounts`,
  getLedger: `${AccountsReports}/getLedger`,
  AccountPayableAndrURL: `${mainBaseUrl}/api/account-payable/Andromeda`,
  AccountReceivableAndrURL: `${mainBaseUrl}/accountReceivable/Andromeda`,
  AccountPayableDsaURL: `${mainBaseUrl}/accountpayblesubDsa`,
  AccountReceivableDsaURL: `${mainBaseUrl}/accountReceivableDsa`,
  EmployeeProductivity: `${mainBaseUrl}/employeeProductivity`,
};

export default URLs;
