import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Dialog,
  DialogContent,
  CircularProgress,
  TableContainer,
  Typography,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  StyledAgGridContainer,
  inputStyle,
} from "components/StyledComponents/styledComponents.js";
import { monthEndStyles } from "components/Styles/monthEndStyles";
import { useAccountsPayableApi } from "customApiHooks/accountsPayableApi";
import { CustomPaper, globalStyles } from "components/Styles/globalStyles";

export default function AccountsPayable() {
  const MInput = inputStyle();
  const [selectedDsaId, setSelectedDsaId] = useState(0);
  const {
    loading,
    message,
    alertType,
    open,
    companies,
    accounts,
    setAlert,
    fetchAccountsPayableAndromeda, // Now properly provided
    fetchAccountsPayableByDsaId,
    setOpen,
    setMessage,
    handleClose,
    setLoading,
  } = useAccountsPayableApi(); // Correct hook used

  const andromedaAccounts = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sortable: true,
      minWidth: 110,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Application Number",
      field: "applicationNumber",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Business Name",
      field: "businessName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Provider Name",
      field: "finainacialInstitutionName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Disbursed Amount",
      field: "disbursedAmount",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "DSA Payout Amount",
      field: "dsaPayoutAmount",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 180,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) => {
        // Check if params.value is defined and a valid number before formatting
        const value = params.value;
        if (value != null && !isNaN(value)) {
          return `₹ ${value.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
        return "₹ 0.00"; // Default value in case of null or undefined
      },
    },
    {
      headerName: "DSA Code",
      field: "dsaCode",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "DSA Name",
      field: "dsaName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
  ];
  const dsaAccounts = [
    {
      headerName: "Date",
      field: "date",
      filter: true,
      sortable: true,
      minWidth: 110,
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Application Number",
      field: "applicationNumber",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Business Name",
      field: "businessName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Provider Name",
      field: "finainacialInstitutionName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Disbursed Amount",
      field: "disbursedAmount",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`, // Formats and rounds to two decimals
    },
    {
      headerName: "Sub DSA Payout Amount",
      field: "subDsaPaymentAmount",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 180,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) => {
        const value = params.value ?? 0; // Fallback to 0 if value is null or undefined
        return `₹ ${value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`; // Formats and rounds to two decimals
      },
    },
    {
      headerName: "Sub DSA Code",
      field: "subDsaCode",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Sub DSA Name",
      field: "subDsaName",
      filter: true,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(andromedaAccounts);
  const updatedCompanies = [{ dsaName: "Andromeda", dsaId: 0 }, ...companies];
  const handleGetClick = () => {
    if (!selectedDsaId) {
      // Set columnDefs to andromedaAccounts when no DSA is selected
      setColumnDefs(andromedaAccounts);
      fetchAccountsPayableAndromeda();
    } else {
      // Set columnDefs to dsaAccounts when a DSA ID is selected
      setColumnDefs(dsaAccounts);
      fetchAccountsPayableByDsaId(selectedDsaId);
    }
  };
  useEffect(() => {
    fetchAccountsPayableAndromeda(); // Call the function after ensuring it's correctly imported
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={globalStyles.accountsTable}>
        <Box sx={globalStyles.card}>
          <Autocomplete
            options={updatedCompanies} // Use the updated list of companies with Andromeda
            getOptionLabel={(option) => option.dsaName || ""} // Display the `dsaName`
            value={updatedCompanies.find((company) => company.dsaId === selectedDsaId)} // Set the default value (Andromeda)
            onChange={(event, newValue) => {
              setSelectedDsaId(newValue ? newValue.dsaId : 0); // Update the selected DSA ID
            }}
            disableClearable={false}
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.toLowerCase();

              // Filter options that start with the input value
              const startsWithInput = options.filter((option) =>
                option.dsaName.toLowerCase().startsWith(inputValue)
              );

              // Filter options that contain the input value but do not start with it
              const containsInput = options.filter(
                (option) =>
                  !option.dsaName.toLowerCase().startsWith(inputValue) &&
                  option.dsaName.toLowerCase().includes(inputValue)
              );

              // Combine the options with priority for startsWithInput
              return [...startsWithInput, ...containsInput];
            }}
            PaperComponent={CustomPaper} // If using a custom Paper component
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label="Select DSA"
                variant="outlined"
                required
                sx={{
                  height: 43,
                  ...MInput.b,
                  "& .MuiInputBase-root": {
                    height: "100%",
                    minHeight: 43,
                    width: "160px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: MInput.a, // Adjust label color as needed
                    "&.Mui-focused": {
                      color: "red", // Focused label color
                    },
                  },
                }}
              />
            )}
          />

          <StyledButton onClick={handleGetClick}>Get</StyledButton>
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={accounts}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={12}
          />
        </StyledAgGridContainer>
      </TableContainer>
    </DashboardLayout>
  );
}
