import { useState, useEffect, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import URLs from "constants/urls";

export default function data() {
  const [loanApplications, setLoanApplications] = useState([]); // State to store loan applications
  const [alert, setAlert] = useState(""); // State to handle alert messages
  const [typeCode, setTypeCode] = useState("BL");
  const [entriesLength, setEntriesLength] = useState(0);

  const fetchBusinessEntriesApiCall = useCallback(async () => {
    const url = `${URLs.baseUrl}/statusAproval?currentStatus=D&businessType=${typeCode}`;
    const options = {
      method: "GET",
    };
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Failed to fetch data");
      const data = await response.json();
      setEntriesLength(data.length);
      setLoanApplications(data); // Set fetched data to loanApplications
    } catch (error) {
      setAlert(`Data not fetched: ${error.message}`);
    }
  }, [typeCode]); // `typeCode` needs to be defined

  // Call the API when the component mounts
  useEffect(() => {
    fetchBusinessEntriesApiCall();
  }, [fetchBusinessEntriesApiCall]);

  const theme = useTheme();
  const colors = [
    theme.palette.primary.light,
    theme.palette.secondary.light,
    theme.palette.success.light,
    theme.palette.warning.light,
    theme.palette.error.light,
  ];

  const Company = ({ name, loanId, color }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <button
        style={{
          height: "25px",
          width: "60px",
          borderRadius: "5px",
          borderWidth: "0px",
          backgroundColor: color,
          color: "#FFFFFF",
          fontSize: "10px",
          fontFamily: "sans-serif",
          textTransform: "none",
          fontWeight: "bold",
        }}
      >
        {name}
      </button>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {loanId}
      </MDTypography>
    </MDBox>
  );

  Company.propTypes = {
    name: PropTypes.string.isRequired,
    loanId: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  };

  // Map loan applications data into rows
  const rows = loanApplications.map((application, index) => ({
    applicationNumber: (
      <Company
        loanId=""
        name={application.applicatioNumber}
        color={colors[index % colors.length]}
      />
    ),
    loanAmount: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        ₹{application.appliedAmount.toLocaleString("en-IN")}
      </MDTypography>
    ),
    manager: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {application.salesManager}
      </MDTypography>
    ),
    dsa: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {application.mainDSA}
      </MDTypography>
    ),
  }));

  return {
    columns: [
      { Header: "Application Number", accessor: "applicationNumber", width: "30%", align: "left" },
      { Header: "Business Amount", accessor: "loanAmount", width: "20%", align: "right" },
      { Header: "Manager Name", accessor: "manager", align: "center" },
      { Header: "DSA Name", accessor: "dsa", align: "center" },
    ],
    rows,
    entriesLength,
  };
}
