import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Alpine theme CSS
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  DialogContentText,
  TableRow,
  Box,
  FormControl,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import MDBox from "components/MDBox";
import {
  ViewButton,
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  CustomTableCell,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents.js";
import {
  receiptDialogStyles,
  typoStyle,
  mdBoxStyle,
  dialogContentStyle,
  containerStyle,
  tableCellStyle,
  tableCellFontStyle,
  subDialogContentStyle,
  dialogContentTextStyle,
} from "components/Styles/approvalStyles";
import { monthEndStyles } from "components/Styles/monthEndStyles";
import useDsaAndEmployeeBusinessApi from "components/GlobalHooks/useMonthlyDsa&EmployeeBusiness";
import useBusinessMonthYear from "components/GlobalHooks/useMonthlyBusiness";
import { selectScrollStyle, CustomPaper } from "components/Styles/globalStyles";

export default function SubDsaPayoutReceipt() {
  const { monthIdData } = useBusinessMonthYear();
  const [openPopup, setOpenPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [monthId, setMonthId] = useState("0");
  const [dsaId, setDsaId] = useState("0");
  const businessMonthIdRef = useRef(0);
  const dsaIdref = useRef(0);
  const MInput = inputStyle();
  const [selectedData, setSelectedData] = useState({
    finalSubDSAGrossPayoutAmount: 0,
  });

  const {
    dsaData,
    loading,
    message,
    alertType,
    open,
    handleClose,
    setLoading,
    subDsaEntries,
    approveEntryApiCall,
    fetchBusinessEntriesBySelf,
    fetchSelfDsaEntriesData,
    fetchEntrieById,
    setAlert,
    entry,
    setOpen,
    setMessage,
  } = useDsaAndEmployeeBusinessApi("subDsa");

  useEffect(() => {
    if (monthIdData?.length > 0) {
      const mId = monthIdData[0].businessMonthID;
      setMonthId(mId);
      fetchBusinessEntriesBySelf(mId, 0);
    }
  }, [monthIdData]);

  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };
  const handleViewClick = async (rowData) => {
    if (rowData.entryId) {
      await fetchEntrieById(rowData.entryId); // Directly use rowData.entryId
    }
    setSelectedData(rowData);
    setOpenPopup(true);
  };

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp); // Convert timestamp to Date object
    return date.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
  }

  const ActionCellRenderer = (props) => {
    const rowData = props.api.getDisplayedRowAtIndex(props.node.rowIndex).data;
    return (
      <ViewButton type="button" onClick={() => handleViewClick(rowData)}>
        <VisibilityIcon />
      </ViewButton>
    );
  };

  const columns = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 120,
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Status",
      field: "currentStatus",
      filter: true,
      sorted: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" },
      valueFormatter: (params) => {
        const statusMapping = {
          N: "New",
          D: "Disbursed",
          R: "Payment Received from Provider",
          C: "Paid to DSA",
          S: "Paid to Sub DSA", // Assuming 'R' for Sub DSA was a typo
        };
        return statusMapping[params.value] || "Unknown Status"; // Fallback for unmapped values
      },
    },
    {
      headerName: "Appl. No.",
      field: "applicatioNumber",
      filter: true,
      sorted: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: "Applied Amount", // dynamic label for the column
      field: "appliedAmount", // this field holds the original numeric value
      filter: "agNumberColumnFilter", // using a number filter to filter by the original numeric value
      sortable: true, // allows sorting by the original numeric value
      minWidth: 150,
      flex: 1.5,
      cellStyle: { textAlign: "right", fontWeight: "500" },

      // Use valueFormatter to format the displayed value (but filtering uses the original value)
      valueFormatter: (params) =>
        `₹ ${params.value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,

      headerClass: "header-center", // optional styling for the header
    },
    {
      headerName: "Sub DSA Name",
      field: "subDSA",
      filter: true,
      sortable: true,
      minWidth: 120,
      flex: 1.5,
    },
    {
      headerName: "Provider Name",
      field: "financialInstitutionName",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Manager",
      field: "salesManager",
      filter: true,
      sortable: true,
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "View",
      cellRenderer: ActionCellRenderer,
      width: 80,
    },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseMd = () => {
    setOpenPopup(false);
  };

  const handleConfirmClick = async () => {
    const data = {
      ...entry,
      businessStatus: "S".charAt(0),
      finalSubDSAGrossPayoutAmount: selectedData.finalSubDSAGrossPayoutAmount,
    };
    await approveEntryApiCall(selectedData.entryId, data);
    setOpenPopup(false);
    setOpenDialog(false);
    triggerApiCall();
    setSelectedData({
      finalSubDSAGrossPayoutAmount: 0,
    });
  };

  const handleCloseP = () => {
    setOpenPopup(false);
  };

  const triggerApiCall = () => {
    businessMonthIdRef.current = monthId;
    dsaIdref.current = dsaId;
    fetchBusinessEntriesBySelf(monthId, dsaId);
  };

  const handlefinalSubDsaGrossPayoutAmountChange = (event) => {
    const rawValue = event.target.value;
    // Allow clearing the input
    if (rawValue === "") {
      setSelectedData({
        ...selectedData,
        finalSubDSAGrossPayoutAmount: "",
      });
      return;
    }
    // Check if the input contains only numbers and up to two decimal places
    if (!/^\d*\.?\d*$/.test(rawValue)) {
      setAlert("Please enter a valid number", "error"); // Custom alert function
      return;
    }
    // Convert the raw value to a number
    const numericValue = parseFloat(rawValue);
    if (!isNaN(numericValue)) {
      // Check if the numeric value is less than or equal to the calculated limit
      if (numericValue <= selectedData.subDsaPaymentAmount) {
        setSelectedData({
          ...selectedData,
          finalSubDSAGrossPayoutAmount: rawValue, // Store the numeric value
        });
      } else {
        setAlert(
          "Final DSA Payout Amount must be less than or equal to Calculated final Provider Payout Amount",
          "error"
        );
      }
    }
  };
  const formattedAmount = selectedData.finalSubDSAGrossPayoutAmount;

  const handleOpenDialog = () => {
    if (
      selectedData.finalSubDSAGrossPayoutAmount == 0 ||
      !selectedData.finalSubDSAGrossPayoutAmount
    ) {
      setAlert("Enter Payout amount", "error");
      return;
    }
    setOpenDialog(true);
  };
  const validateNumericInput = (value, max = null, isPercentage = false) => {
    // Check if value is numeric or contains only valid characters
    const isValid = value === "" || /^[0-9]*(\.[0-9]{0,3})?$/.test(value);
    if (!isValid) {
      // Check if the input has more than three decimal places
      if (/^[0-9]*(\.[0-9]{4,})$/.test(value)) {
        setAlert("We do not accept more than three decimal places", "error");
      } else {
        setAlert("Enter numeric values only", "error");
      }
      return false;
    }

    // Convert value to a number if it's not empty
    const numericValue = value;
    if (isNaN(numericValue)) return true; // Allow clearing the field

    // Ensure value is within range for percentages
    if (isPercentage && (numericValue < 0 || numericValue > 100)) {
      setAlert("Value must be between 0 and 100", "error");
      return false;
    }

    // Ensure value doesn't exceed the max amount
    if (max !== null && numericValue > max) {
      setAlert(`Value cannot exceed ${max}`, "error");
      return false;
    }

    return true;
  };

  const handleSubDsaPayoutPercentChange = (event) => {
    const value = event.target.value;
    if (!validateNumericInput(value, 100, true)) return;

    const subDsaPayoutPercent = value || 0;
    const finalDsaPayoutPercent =
      subDsaPayoutPercent - subDsaPayoutPercent * (selectedData.subDsaTdsPercent / 100);

    const calculatedSubDsaPayoutAmount =
      (subDsaPayoutPercent / 100) * selectedData.finalDsaGrossPayoutAmount;
    const finalSubDsaPayoutAmount =
      (finalDsaPayoutPercent / 100) * selectedData.finalDsaGrossPayoutAmount;

    setSelectedData({
      ...selectedData,
      subDsaPayoutPercent: subDsaPayoutPercent,
      subDsaPaymentAmount: parseFloat(calculatedSubDsaPayoutAmount.toFixed(2)),
      finalSubDSAGrossPayoutAmount: parseFloat(finalSubDsaPayoutAmount.toFixed(2)),
    });
  };

  const handleTdsPercentChange = (event) => {
    const value = event.target.value;
    if (!validateNumericInput(value, 100, true)) return;
    const tdsPercent = value;
    setSelectedData({
      ...selectedData,
      subDsaTdsPercent: tdsPercent,
    });
    const calculatedSubDsaPayoutAmount =
      (selectedData.subDsaPayoutPercent / 100) *
      (1 - tdsPercent / 100) *
      selectedData.finalDsaGrossPayoutAmount;
    setSelectedData({
      ...selectedData,
      subDsaTdsPercent: tdsPercent || 0,
      finalSubDSAGrossPayoutAmount: parseFloat(calculatedSubDsaPayoutAmount.toFixed(2)),
    });
  };

  const handleActualSubDsaPayoutAmountChange = (event) => {
    const value = event.target.value;
    if (!validateNumericInput(value)) return;
    if (value > selectedData.finalDsaGrossPayoutAmount) {
      setAlert("Actual Sub DSA Payout should be less than Final DSA Payout Amount", "error");
      return;
    }
    const actualSubDsaPayoutAmount = value || 0;
    const tdsPercent = selectedData.subDsaTdsPercent || 0; // Ensure no undefined value
    const finalDsaGrossPayoutAmount = selectedData.finalDsaGrossPayoutAmount || 1; // Avoid division by zero
    const effectiveMultiplier = 1 - tdsPercent / 100;
    // Calculate dsaPayoutPercent
    const subDsaPayoutPercent =
      finalDsaGrossPayoutAmount > 0
        ? actualSubDsaPayoutAmount / (effectiveMultiplier * finalDsaGrossPayoutAmount)
        : 0;
    const calculatedSubDsaPayoutAmount = subDsaPayoutPercent * finalDsaGrossPayoutAmount;
    setSelectedData({
      ...selectedData,
      subDsaPaymentAmount: parseFloat(calculatedSubDsaPayoutAmount.toFixed(2)),
      finalSubDSAGrossPayoutAmount: actualSubDsaPayoutAmount,
      subDsaPayoutPercent: subDsaPayoutPercent >= 0 ? subDsaPayoutPercent * 100 : 0,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <TableContainer component={Paper} sx={monthEndStyles.tableContainer}>
        <Box sx={monthEndStyles.formContainer}>
          <Box sx={monthEndStyles.formItem}>
            <Autocomplete
              options={monthIdData}
              getOptionLabel={(option) => option.date} // Display 'date' field from monthIdData
              value={monthIdData.find((item) => item.businessMonthID === monthId) || null} // Set the value based on monthId
              onChange={(event, newValue) => {
                if (newValue) {
                  setMonthId(newValue.businessMonthID);
                }
              }}
              disableClearable={false}
              PaperComponent={CustomPaper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Date"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "160px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              options={[{ dsaName: "All", dsaId: 0 }, ...dsaData]}
              getOptionLabel={(option) => option.dsaName}
              value={dsaData.find((item) => item.dsaId === dsaId) || { dsaName: "All", dsaId: 0 }}
              onChange={(event, newValue) => {
                if (newValue) {
                  setDsaId(newValue.dsaId);
                }
              }}
              PaperComponent={CustomPaper}
              disableClearable={false}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();

                // Filter options where the label starts with the input value
                const startsWithInput = options.filter((option) =>
                  option.dsaName.toLowerCase().startsWith(inputValue)
                );

                // Filter options where the label contains the input value but does not start with it
                const containsInput = options.filter(
                  (option) =>
                    !option.dsaName.toLowerCase().startsWith(inputValue) &&
                    option.dsaName.toLowerCase().includes(inputValue)
                );

                // Combine and return the filtered results
                return [...startsWithInput, ...containsInput];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dsa Name"
                  variant="outlined"
                  required
                  sx={{
                    height: 43,
                    width: "100%", // Adjust to be responsive
                    minWidth: "170px",
                    maxWidth: "200px", // Set a maximum width
                    ...MInput.b,
                    "& .MuiInputBase-root": {
                      height: "100%",
                      minHeight: 43,
                      width: "100%", // Ensures the input doesn't shrink too much
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: MInput.a, // Ensure this applies to the label
                      "&.Mui-focused": {
                        color: "red", // Focused label color
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.dsaId === value.dsaId}
            />
            <StyledButton onClick={triggerApiCall}>Get</StyledButton>
          </Box>
        </Box>
        <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
          <AgGridReact
            rowData={subDsaEntries}
            columnDefs={columns}
            pagination={true}
            paginationPageSize={10}
          />
        </StyledAgGridContainer>
      </TableContainer>
      <Dialog
        open={openPopup}
        onClose={handleCloseP}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          ...receiptDialogStyles,
        }}
      >
        <DialogTitle sx={{ position: "relative", padding: "0px", zIndex: "1000" }}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
            sx={{ ...mdBoxStyle, backgroundColor: MInput.a }}
          >
            <Typography variant="h6" fontWeight="medium" color="#FFFFFF" {...typoStyle}>
              Payment Confirmation
            </Typography>
          </MDBox>
        </DialogTitle>
        <DialogContent {...dialogContentStyle}>
          <Grid container spacing={2} pt={2}>
            <StyledSnackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              severity={alertType}
            >
              <StyledAlert onClose={handleClose} severity={alertType}>
                {message}
              </StyledAlert>
            </StyledSnackbar>
            <Grid item xs={12} md={3}>
              <TextField
                label="Insurance Id"
                fullWidth
                value={selectedData.entryId}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label="Date"
                fullWidth
                value={
                  selectedData?.date
                    ? formatTimestampToDate(selectedData.date)
                    : new Date().toLocaleDateString()
                }
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Application Number"
                fullWidth
                value={selectedData.applicatioNumber}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="DSA Name"
                fullWidth
                value={selectedData.mainDSA || "Unknown DSA"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Sub DSA"
                fullWidth
                value={selectedData.subDSA || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Andromeda Manager"
                fullWidth
                value={selectedData.salesManager || "Unknown Manager"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Dsa Sales Manager"
                fullWidth
                value={selectedData.dsaSalesManager || "N/A"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Service Type"
                fullWidth
                value={selectedData.businessName || "Unknown Policy"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Customer Name"
                fullWidth
                value={selectedData.customerName}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Provider Name"
                fullWidth
                value={selectedData.financialInstitutionName || "Unknown Bank"}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Applied Amount"
                fullWidth
                value={`₹ ${selectedData?.appliedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Disbursed Amount"
                fullWidth
                value={`₹ ${selectedData?.disbursedAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Final Gross Payout Amount"
                fullWidth
                value={`₹ ${selectedData?.finalGrossPayoutAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Actuall DSA Payout Amount"
                fullWidth
                value={`₹ ${selectedData?.dsaPayoutAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Final DSA Payout Amount"
                fullWidth
                value={`₹ ${selectedData?.finalDsaGrossPayoutAmount?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Remarks"
                multiline
                rows={1}
                fullWidth
                value={selectedData.remarks}
                InputProps={{
                  readOnly: true,
                }}
                sx={MInput.b}
                InputLabelProps={{
                  sx: {
                    color: MInput.a,
                  },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} {...containerStyle}>
            <Table>
              <TableBody>
                <TableRow {...tableCellStyle}>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Sub DSA Payout %</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>TDS %</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Calculated Sub DSA Payout Amount</strong>
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <Typography variant="body2" {...tableCellFontStyle}>
                      <strong>Actual Sub DSA Payout Amount</strong>
                    </Typography>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <TextField
                      label=""
                      value={selectedData.subDsaPayoutPercent || ""}
                      onChange={handleSubDsaPayoutPercentChange}
                      sx={{ ...MInput.b, maxWidth: "120px" }}
                      inputProps={{
                        inputMode: "decimal", // Allows numeric input with decimal on mobile
                        pattern: "^[0-9]*(\\.[0-9]{0,3})?$", // Up to 3 decimal places
                      }}
                      InputLabelProps={{
                        sx: { color: MInput.a },
                      }}
                      error={
                        selectedData.dsaPayoutPercent > 100 || selectedData.dsaPayoutPercent < 0
                      } // Error if out of range
                      helperText={
                        selectedData.dsaPayoutPercent > 100 || selectedData.dsaPayoutPercent < 0
                          ? "Enter a value between 0 and 100"
                          : ""
                      }
                    />
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <TextField
                      label=""
                      value={selectedData.subDsaTdsPercent || ""}
                      onChange={handleTdsPercentChange}
                      sx={{ ...MInput.b, maxWidth: "120px" }}
                      inputProps={{
                        inputMode: "decimal", // Allows numeric input with decimal on mobile
                        pattern: "^[0-9]*(\\.[0-9]{0,3})?$", // Up to 3 decimal places
                      }}
                      InputLabelProps={{
                        sx: { color: MInput.a },
                      }}
                      error={
                        selectedData.subDsaTdsPercent > 100 || selectedData.subDsaTdsPercent < 0
                      } // Error if out of range
                      helperText={
                        selectedData.subDsaTdsPercent > 100 || selectedData.subDsaTdsPercent < 0
                          ? "Enter a value between 0 and 100"
                          : ""
                      }
                    />
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <TextField
                      label=""
                      value={selectedData.subDsaPaymentAmount || ""}
                      InputProps={{ readOnly: true }}
                      sx={{ ...MInput.b, maxWidth: "200px" }}
                      InputLabelProps={{
                        sx: { color: MInput.a },
                      }}
                    />
                  </CustomTableCell>
                  <CustomTableCell align="center" minWidth="100px" flexGrow={1} fontWeight="bold">
                    <TextField
                      label=""
                      value={selectedData.finalSubDSAGrossPayoutAmount || ""}
                      onChange={handleActualSubDsaPayoutAmountChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        inputProps: {
                          min: 0, // Ensures only positive numbers can be entered
                          inputMode: "decimal", // Allows numeric input with decimal on mobile
                          pattern: "^[0-9]*(\\.[0-9]{0,3})?$", // Up to 3 decimal places
                        },
                      }}
                      InputLabelProps={{
                        sx: { color: MInput.a },
                      }}
                      error={selectedData.finalSubDSAGrossPayoutAmount < 0} // Error if value is invalid
                      helperText={
                        selectedData.finalSubDSAGrossPayoutAmount < 0
                          ? "Enter a valid positive amount"
                          : ""
                      }
                      sx={{ ...MInput.b, maxWidth: "200px" }}
                    />
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent sx={subDialogContentStyle}>
            <DialogContentText sx={dialogContentTextStyle}>
              {`Are you sure you want to confirm the payment 
              ${selectedData.finalSubDSAGrossPayoutAmount} made to
                ${selectedData.subDSA || "Unknown Bank"} for application number ${
                selectedData.applicatioNumber
              }?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={monthEndStyles.dialogActions}>
            <StyledButton onClick={handleConfirmClick}>Yes</StyledButton>
            <StyledButton onClick={handleCloseDialog} variant="outlined">
              No
            </StyledButton>
          </DialogActions>
        </Dialog>
        <DialogActions>
          <StyledButton onClick={handleOpenDialog}>Confirm</StyledButton>
          <StyledButton onClick={handleCloseMd}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
SubDsaPayoutReceipt.propTypes = {
  data: PropTypes.object.isRequired,
  node: PropTypes.shape({
    rowIndex: PropTypes.number.isRequired,
  }).isRequired,
  api: PropTypes.shape({
    getDisplayedRowAtIndex: PropTypes.func.isRequired,
  }).isRequired,
};
