export default function sidenavLogoLabel(theme, ownerState) {
  const { functions, transitions, typography, breakpoints } = theme;
  const { miniSidenav } = ownerState;

  const { pxToRem } = functions;
  const { fontWeightMedium } = typography;

  return {
    ml: 0.5,
    fontWeight: fontWeightMedium,
    wordSpacing: pxToRem(-1),
    transition: transitions.create("opacity", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up(1500)]: {
      opacity: miniSidenav ? 0 : 1,
    },
  };
}

export const finTitle = {
  fontFamily: `"Segoe UI","Arial", "sans-serif"`,
  fontSize: {
    xs: "15px",
    sm: "18px",
    md: "20px",
    lg: "25px",
  },
  color: "#FFFFFF",
  letterSpacing: "0.7px",
  background: `linear-gradient(45deg, #ff6f61, #ffffff)`,
  paddingLeft: "10px",
};

export const sideNavScrollStyle = {
  maxHeight: "700px",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "8px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Color of the scrollbar thumb
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#FFFFFF", // Color of the scrollbar thumb on hover
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Color of the scrollbar track
  },
};
