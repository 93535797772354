const colors = {
  background: {
    // default: "#E0E3E9", // Slightly darker soft neutral background
    default: "#E3E8EB",
  },

  text: {
    main: "#5C666E", // Darker muted gray for main text
    focus: "#3D474E", // Darker gray for focus
  },

  transparent: {
    main: "transparent",
    secondary: "#4A8974",
    primary: "#2E6E9E",
    focus: "#1F4A6D", // Darker soft blue for better readability on hover
    terinary: "#305471",
  },

  white: {
    main: "#FFFFFF",
    focus: "#FFFFFF",
    secondary: "#333A42", // Darker gray for contrast
  },
  black: {
    light: "#15181B", // Darker light black
    main: "#15181B", // Darker main black
    focus: "#15181B", // Consistent dark black for focus
  },
  primary: {
    main: "#B45E8E", // Darker soft lavender-pink
    focus: "#A3437D", // Darker focus color
  },
  secondary: {
    main: "#5B616B", // Darker muted cool gray
    focus: "#4D545D", // Darker focus color
  },
  info: {
    main: "#2E6E9E", // Darker calmer blue
    focus: "#245A7C", // Darker focus color
  },
  success: {
    main: "#4A8974", // Darker balanced green
    focus: "#3E755D", // Darker focus color
  },
  warning: {
    main: "#D49A2A", // Darker warm orange
    focus: "#C88C30", // Darker focus color
  },
  error: {
    main: "#C76D6D", // Darker muted red
    focus: "#B06363", // Darker focus color
  },
  light: {
    main: "#D5D9DD", // Darker soft light gray
    focus: "#C4C9CE", // Darker focus light gray
  },
  dark: {
    main: "#293642", // Darker soft dark blue-gray
    focus: "#232D36", // Darker focus color
    secondary: "#3A424B", // Darker secondary dark
  },
  grey: {
    100: "#E1E3E6",
    200: "#D1D5D9",
    300: "#B3B8BE",
    400: "#A4A9B1",
    500: "#828A91",
    600: "#68717A",
    700: "#47505E",
    800: "#333A45",
    900: "#21262D",
  },
  gradients: {
    primary: {
      main: "#B2628E", // Darker gradient pink
      state: "#A04B78", // Darker state color
    },

    secondary: {
      main: "#5B616B",
      state: "#4A505A",
    },

    info: {
      main: "#4B8FC2",
      state: "#327DA7",
    },

    success: {
      main: "#61B78D",
      state: "#489D71",
    },

    warning: {
      main: "#E4B054",
      state: "#D4A14C",
    },

    error: {
      main: "#C36B6B",
      state: "#B05C5C",
    },

    light: {
      main: "#E1E3E6",
      state: "#C4CED2",
    },
    dark: {
      main: "#2C3E50", // Darker muted navy blue
      state: "#243B45", // Darker shade for state changes
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3A579A",
      dark: "#334A85",
    },

    twitter: {
      main: "#6AAFE6",
      dark: "#4D99CE",
    },

    instagram: {
      main: "#2D637A",
      dark: "#265566",
    },

    linkedin: {
      main: "#0078B4",
      dark: "#006696",
    },

    pinterest: {
      main: "#C23D3D",
      dark: "#A93232",
    },

    youtube: {
      main: "#D5433F",
      dark: "#B2352E",
    },

    vimeo: {
      main: "#17ADD8",
      dark: "#1290B7",
    },

    slack: {
      main: "#3CA884",
      dark: "#309A72",
    },

    dribbble: {
      main: "#D472A7",
      dark: "#B25988",
    },

    github: {
      main: "#2B2B2B",
      dark: "#1E1E1E",
    },

    reddit: {
      main: "#F06447",
      dark: "#D74E3A",
    },

    tumblr: {
      main: "#35485B",
      dark: "#2A3A4A",
    },
  },

  badgeColors: {
    primary: {
      background: "#D5A6C6", // Darker primary badge background
      text: "#A44A76",
    },

    secondary: {
      background: "#B7BDC2", // Darker secondary badge background
      text: "#555D6B",
    },

    info: {
      background: "#93C9E5", // Darker info badge background
      text: "#236DA8",
    },

    success: {
      background: "#A1D8C3", // Darker success badge background
      text: "#4B805A",
    },

    warning: {
      background: "#FFE8B4", // Darker warning badge background
      text: "#B77D29",
    },

    error: {
      background: "#F4C6C5", // Darker error badge background
      text: "#B9584C",
    },

    light: {
      background: "#F3F5F7", // Darker light badge background
      text: "#A0AAB6",
    },

    dark: {
      background: "#596D8A", // Darker dark badge background
      text: "#2A3847",
    },
  },

  coloredShadows: {
    primary: "#B45E8E", // Darker primary shadow
    secondary: "#353B40", // Darker secondary shadow
    info: "#2F7A99", // Darker info shadow
    success: "#4C8B69", // Darker success shadow
    warning: "#D49351", // Darker warning shadow
    error: "#C26868", // Darker error shadow
    light: "#A5B3B8", // Darker light shadow
    dark: "#282D35", // Darker dark shadow
  },

  inputBorderColor: "#B7BCC4", // Darker input border color

  tabs: {
    indicator: { boxShadow: "#A8ACB0" }, // Darker shadow for tabs
  },
};

export default colors;
