import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  TableContainer,
  Paper,
  Grid,
  Box,
  Dialog,
  TextField,
  DialogContent,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import MDBox from "components/MDBox";
import { writeFile, utils } from "xlsx";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  StyledButton,
  StyledAlert,
  StyledSnackbar,
  inputStyle,
  StyledAgGridContainer,
} from "components/StyledComponents/styledComponents";
import { ledgerStyles } from "components/Styles/LedgerStyles";
import {
  selectScrollStyle,
  CustomPaper,
  tableShadow,
  autoSelectStyle,
  globalStyles,
} from "components/Styles/globalStyles";
import URLs from "constants/urls";
import { voucherMasterStyles } from "components/Styles/voucherStyles";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export default function Ledger() {
  const [ledgerData, setLedgerData] = useState([]);
  const [SelectCompany, setSelectCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [Company, SelectedCompany] = useState("");
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [selectedLedger, setSelectedLedger] = useState("");
  const [fromDate, setFromDate] = useState(new Date().toISOString().split("T")[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const MInput = inputStyle();
  const theme = useTheme();
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Fetch all ledger accounts
  const fetchLedgerAccounts = async () => {
    try {
      const response = await fetch(URLs.GetLedgerAccounts);
      if (!response.ok) {
        throw new Error("Failed to fetch ledger accounts");
      }
      const data = await response.json();
      setLedgerAccounts(data);
    } catch (error) {
      console.error("Error fetching ledger accounts:", error);
    }
  };
  const fetchCompanies = async () => {
    try {
      const response = await fetch(URLs.GetCompanies);
      const data = await response.json();
      setCompanies(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching companies:", error);
      setCompanies([]);
    }
  };
  const fetchLedgerData = async () => {
    if (!SelectCompany || !selectedLedger || !fromDate || !toDate) {
      setAlert("Please select all required fields", "error");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(URLs.getLedger, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyID: SelectCompany,
          ledgerID: selectedLedger,
          fromDate: fromDate,
          toDate: toDate,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch ledger data.");
      }

      const data = await response.json();
      if (data.length > 0) {
        const lastIndex = data.length - 1;
        data[lastIndex] = { ...data[lastIndex], date: "" };
      }
      setLedgerData(data);
    } catch (error) {
      setAlert("Failed to fetch ledger data. Please try again", "error");
    } finally {
      setLoading(false);
    }
  };

  // Fetch ledger data based on selected account

  useEffect(() => {
    fetchLedgerAccounts();
    fetchCompanies(); // Fetch ledger accounts on component mount
  }, []);

  useEffect(() => {
    const selected = companies.find((item) => item.companyId === SelectCompany);
    SelectedCompany(selected ? selected.companyName : "");
  }, [SelectCompany]);

  const downloadLedgerData = () => {
    if (!ledgerData.length) return;
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(ledgerData);
    utils.book_append_sheet(wb, ws, "LedgerData");
    writeFile(wb, "Ledger_Data.xlsx");
  };
  const menuProps = {
    PaperProps: {
      sx: {
        ...selectScrollStyle,
      },
    },
  };

  const ActionCellRenderer = () => (
    <StyledButton onClick={downloadLedgerData}>
      <DownloadForOfflineIcon fontSize="medium" />
    </StyledButton>
  );

  ActionCellRenderer.propTypes = {
    data: PropTypes.shape({
      accountID: PropTypes.number.isRequired,
    }).isRequired,
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 100,
      filter: true,
      sortable: true,
      valueFormatter: (params) => {
        if (!params.value) {
          // Return an empty string if the date is empty or null
          return "";
        }
        const date = new Date(params.value); // Convert the timestamp to a Date object
        const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero
        const month = date.toLocaleString("default", { month: "short" }); // Get month as short name
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return in "dd-MMM-yyyy" format
      },
    },
    {
      headerName: "Ledger Name",
      field: "ledgerName",
      minWidth: 150,
      flex: 1,
      filter: true,
      sortable: true,
    },
    {
      headerName: "Debit",
      field: "debit",
      minWidth: 100,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value;
        return value != null
          ? `₹ ${value.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "₹ 0.00"; // Default value for null or undefined
      },
    },
    {
      headerName: "Credit",
      field: "credit",
      minWidth: 100,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value;
        return value != null
          ? `₹ ${value.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "₹ 0.00"; // Default for null or undefined
      },
    },
    {
      headerName: "Balance",
      field: "balance",
      minWidth: 100,
      flex: 1,
      filter: true,
      sortable: true,
      cellStyle: { textAlign: "right", fontWeight: "500" },
      valueFormatter: (params) => {
        const value = params.value;
        return value != null
          ? `₹ ${value.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "₹ 0.00"; // Default for null or undefined
      },
    },
    {
      headerName: "Balance Type",
      field: "balanceType",
      minWidth: 110,
      filter: true,
      sortable: true,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Loading Dialog */}
      <Dialog open={loading} onClose={() => setLoading(false)}>
        <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading, please wait...
          </Typography>
        </DialogContent>
      </Dialog>
      <StyledSnackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={alertType}
      >
        <StyledAlert onClose={handleClose} severity={alertType}>
          {message}
        </StyledAlert>
      </StyledSnackbar>
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper} sx={globalStyles.accountsTable}>
          <MDBox sx={ledgerStyles.mdBoxStyle} mt={1} mb={2}>
            <Grid container spacing={2} alignItems="center" wrap="wrap">
              <Grid item xs={12} sm={6} md={1.5}>
                <TextField
                  label="From Date"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  fullWidth
                  sx={MInput.b}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: MInput.a,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={1.5}>
                <TextField
                  label="To Date"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  fullWidth
                  sx={MInput.b}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: MInput.a,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={companies}
                  getOptionLabel={(option) => option.companyName || ""}
                  value={
                    SelectCompany
                      ? companies.find((item) => item.companyId === SelectCompany)
                      : null
                  }
                  onChange={(event, newValue) => {
                    setSelectCompany(newValue ? newValue.companyId : "");
                  }}
                  filterOptions={(options, state) => {
                    const input = state.inputValue.toLowerCase();

                    // Filter options that start with the input value
                    const startsWithInput = options.filter((option) =>
                      option.companyName.toLowerCase().startsWith(input)
                    );

                    // Filter options that contain the input value but do not start with it
                    const containsInput = options.filter(
                      (option) =>
                        !option.companyName.toLowerCase().startsWith(input) &&
                        option.companyName.toLowerCase().includes(input)
                    );

                    // Combine the options: starts with input first, then the rest
                    return [...startsWithInput, ...containsInput];
                  }}
                  PaperComponent={CustomPaper}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        height: 43,
                        ...autoSelectStyle,
                        ...MInput.b,
                      }}
                      InputLabelProps={{
                        sx: {
                          color: MInput.a, // Default label color
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <Autocomplete
                  options={ledgerAccounts}
                  getOptionLabel={(option) => option.accountName || ""}
                  value={
                    selectedLedger
                      ? ledgerAccounts.find((item) => item.accountID === selectedLedger)
                      : null
                  }
                  onChange={(event, newValue) => {
                    setSelectedLedger(newValue ? newValue.accountID : "");
                  }}
                  filterOptions={(options, state) => {
                    const input = state.inputValue.toLowerCase();

                    // Filter options that start with the input value
                    const startsWithInput = options.filter((option) =>
                      option.accountName.toLowerCase().startsWith(input)
                    );

                    // Filter options that contain the input value but do not start with it
                    const containsInput = options.filter(
                      (option) =>
                        !option.accountName.toLowerCase().startsWith(input) &&
                        option.accountName.toLowerCase().includes(input)
                    );

                    // Combine the options: starts with input first, then the rest
                    return [...startsWithInput, ...containsInput];
                  }}
                  PaperComponent={CustomPaper}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Ledger"
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        height: 43,
                        ...autoSelectStyle,
                        ...MInput.b,
                      }}
                      InputLabelProps={{
                        sx: {
                          color: MInput.a, // Default label color
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <StyledButton type="submit" onClick={fetchLedgerData} fullWidth>
                  Display
                </StyledButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <StyledButton onClick={downloadLedgerData}>
                <DownloadForOfflineIcon fontSize="medium" />
              </StyledButton>
            </Grid>
          </MDBox>
          <StyledAgGridContainer component={Paper} className={`ag-theme-alpine`}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={ledgerData}
              pagination={true}
              paginationPageSize={12}
            />
          </StyledAgGridContainer>
        </TableContainer>
      </Grid>
    </DashboardLayout>
  );
}
